import { ses_content } from "assets/assetsManager";
import { langType } from "app/redux/types";

const {
  CHANGE_LANGUAGE_FAILURE,
  CHANGE_LANGUAGE_REQUEST,
  CHANGE_LANGUAGE_SUCCESS,
} = langType;

const initState = {
  content: ses_content,
  preferd_language: localStorage.getItem("ses_lang") || "fr",
  IsInProgress: false,
  error: null,
};

const contentReducer = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_REQUEST:
      return {
        ...state,
        IsInProgress: true,
      };
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        IsInProgress: false,
        error: null,
        preferd_language: action.payload,
      };
    case CHANGE_LANGUAGE_FAILURE:
      return {
        ...state,
        IsInProgress: false,
        error: action.payload,
        preferd_language: "fr",
      };
    default:
      return state;
  }
};

export default contentReducer;
