import React, { Suspense } from "react";
import {
  lazyLoadAdminComponents,
  lazyLoadGuestComponents,
} from "app/bundles/lazyComponents";
import Home from "pages/admin/Home";
import AdminBlog from "pages/admin/Blog";
import ContactAdmin from "pages/admin/ContactAdmin";
import QuotesAdmin from "pages/admin/QuotesAdmin";
import Equipe from "pages/admin/Equipe";
import ProductsAdmin from "pages/admin/ProductsAdmin";
import Realisations from "pages/admin/Realisations";
import Clients from "pages/admin/Clients";
import {
  ArtTrackRounded,
  ContactPageRounded,
  Dashboard,
  GroupRounded,
  GroupsRounded,
  Inventory,
  NewspaperRounded,
  PointOfSaleRounded,
} from "@mui/icons-material";

const { SignIn, Dash } = lazyLoadAdminComponents;
const {
  BlogDetails,
  About,
  Contact,
  Landing,
  Products,
  ProductDetails,
  Quote,
  Team,
  Blog,
} = lazyLoadGuestComponents;

export const scopes = {
  guest: {
    home: "/",
    about: "/qui-sommes-nous",
    contact: "/contact",
    product: "/produits",
    productDetail: "/produits/:id",
    team: "/equipe-comerciale",
    quote: "/demander-un-devis",
    blog: "/blog",
    blogDetails: "/blog/:id",
    signIn: "/admin", // should be in guest only
  },

  admin: {
    home: "/admin",
    products: "/admin/produits",
    blog: "/admin/blog",
    devis: "/admin/devis",
    realisations: "/admin/realisations",
    clients: "/admin/clients",
    equipe: "/admin/equipe",
    contact: "/admin/contact",
  },

  errors: {
    notFound: "*",
  },
};

export const listItems = [
  {
    icon: <Dashboard color="primary" />,
    text: "Tableau de bord",
    url: scopes.admin.home,
  },
  {
    icon: <NewspaperRounded color="primary" />,
    text: "Blog",
    url: scopes.admin.blog,
  },
  {
    icon: <ArtTrackRounded color="primary" />,
    text: "Réalisations",
    url: scopes.admin.realisations,
  },
  {
    icon: <Inventory color="primary" />,
    text: "Produits",
    url: scopes.admin.products,
  },
  {
    icon: <PointOfSaleRounded color="primary" />,
    text: "Devis",
    url: scopes.admin.devis,
  },
  {
    icon: <GroupsRounded color="primary" />,
    text: "Clients",
    url: scopes.admin.clients,
  },
  {
    icon: <GroupRounded color="primary" />,
    text: "Equipe",
    url: scopes.admin.equipe,
  },
  {
    icon: <ContactPageRounded color="primary" />,
    text: "Contact",
    url: scopes.admin.contact,
  },
];

export const adminList = [
  {
    path: scopes.admin.home,
    component: () => (
      <Suspense fallback="">
        <Dash>
          <Home />
        </Dash>
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.admin.blog,
    component: () => (
      <Suspense fallback="">
        <Dash>
          <AdminBlog />
        </Dash>
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.admin.clients,
    component: () => (
      <Suspense fallback="">
        <Dash>
          <Clients />
        </Dash>
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.admin.contact,
    component: () => (
      <Suspense fallback="">
        <Dash>
          <ContactAdmin />
        </Dash>
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.admin.devis,
    component: () => (
      <Suspense fallback="">
        <Dash>
          <QuotesAdmin />
        </Dash>
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.admin.equipe,
    component: () => (
      <Suspense fallback="">
        <Dash>
          <Equipe />
        </Dash>
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.admin.products,
    component: () => (
      <Suspense fallback="">
        <Dash>
          <ProductsAdmin />
        </Dash>
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.admin.realisations,
    component: () => (
      <Suspense fallback="">
        <Dash>
          <Realisations />
        </Dash>
      </Suspense>
    ),
    exact: true,
  },
];

export const guestList = [
  /* ADMIN SIGN IN */
  {
    path: scopes.guest.signIn,
    component: () => (
      <Suspense fallback="">
        <SignIn />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.guest.home,
    component: () => (
      <Suspense fallback="">
        <Landing />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.guest.about,
    component: () => (
      <Suspense fallback="">
        <About />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.guest.blog,
    component: () => (
      <Suspense fallback="">
        <Blog />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.guest.blogDetails,
    component: () => (
      <Suspense fallback="">
        <BlogDetails />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.guest.contact,
    component: () => (
      <Suspense fallback="">
        <Contact />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.guest.product,
    component: () => (
      <Suspense fallback="">
        <Products />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.guest.productDetail,
    component: () => (
      <Suspense fallback="">
        <ProductDetails />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.guest.quote,
    component: () => (
      <Suspense fallback="">
        <Quote />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: scopes.guest.team,
    component: () => (
      <Suspense fallback="">
        <Team />
      </Suspense>
    ),
    exact: true,
  },
];
