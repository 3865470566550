import {
  ArrowBackRounded,
  ContactPageRounded,
  DashboardRounded,
  DeleteRounded,
  LoupeRounded,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { scopes } from "app/routes/routeList";
import CardDashPaper from "components/cards/CardDashPaper";
import BreadCrumbsNav from "components/navigation/BreadCrumbsNav";
import Title from "components/Text/Title";
import React, { Fragment, useEffect, useState } from "react";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { GRID_DEFAULT_LOCALE_TEXT } from "utils/localtextGridTable";
import CustomNoRowsOverlay from "utils/CustomNoRowsOverlay";
import CustomLoadingOverlay from "utils/CustomLoadingOverlay";
import CustomToolbar from "utils/CustomToolBar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteContact,
  getContactList,
} from "app/redux/actions/contactActions";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const ContactAdmin = () => {
  const contactState = useSelector((state) => state.contactState);
  const { contacts, loading_contact } = contactState;

  const [openDialogDetails, setopenDialogDetails] = useState(false);
  const [selectedContact, setselectedContact] = useState({});

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    name: true,
    company: true,
    phone: true,
    email: true,
    content: true,
    createdAt: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactList());
  }, [dispatch]);

  const navigationBearcrumbs = [
    {
      label: "Tableau de bord",
      path: scopes.admin.home,

      icon: <DashboardRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: false,
    },
    {
      label: "Contact",
      path: scopes.admin.contact,

      icon: <ContactPageRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: true,
    },
  ];

  const columns = [
    {
      headerName: "Actions 👇",
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteRounded color="error" />}
          label="Supprimer"
          onClick={() => deleteRow(params)}
        />,
        <GridActionsCellItem
          icon={<LoupeRounded color="info" />}
          label="Voir les détails"
          onClick={() => openDetails(params)}
        />,
      ],
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "company",
      headerName: "Entreprise",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "phone",
      headerName: "Téléphone",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "content",
      headerName: "Message",
      flex: 1,
      minWidth: 150,
      // sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <p>{params.value}</p>
        </Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      type: "dateTime",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        // format to date time : dd MMM yyyy HH:mm
        return format(new Date(params.row.createdAt), "dd MMM yyyy HH:mm", {
          locale: fr,
        });
      },
      // sortable: false,
    },
  ];

  const deleteRow = (params) => {
    dispatch(deleteContact(params.row.id));
  };

  const openDetails = (params) => {
    setselectedContact(params.row);
    setopenDialogDetails(true);
  };

  const handleCloseDialogDetails = () => {
    setopenDialogDetails(false);
    setselectedContact({});
  };
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BreadCrumbsNav navigation={navigationBearcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <CardDashPaper>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Title
                title="Contacts"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              />
              <div style={{ display: "flex", height: 500, width: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={contacts}
                    columns={columns}
                    localeText={GRID_DEFAULT_LOCALE_TEXT}
                    loading={loading_contact}
                    aria-label="contact_table"
                    aria-labelledby="contact_table_id"
                    disableSelectionOnClick
                    componentsProps={{
                      toolbar: { csvOptions: { disableToolbarButton: true } },
                    }}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "createdAt", sort: "desc" }],
                      },
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setColumnVisibilityModel(newModel)
                    }
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      LoadingOverlay: CustomLoadingOverlay,
                      Toolbar: CustomToolbar,
                    }}
                  />
                </div>
              </div>
            </Stack>
          </CardDashPaper>
        </Grid>
      </Grid>
      <DialogDetailsContact
        title="Détails du contact"
        openDialogDetails={openDialogDetails}
        selectedContact={selectedContact}
        handleCloseDialogDetails={handleCloseDialogDetails}
      />
    </Fragment>
  );
};

export default ContactAdmin;

const DialogDetailsContact = ({
  title,
  openDialogDetails,
  selectedContact,
  handleCloseDialogDetails,
}) => {
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={openDialogDetails}
      onClose={handleCloseDialogDetails}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <Divider
        sx={{
          my: 2,
        }}
      />
      <DialogContent>
        {selectedContact && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h6" align="left" gutterBottom>
                Nom : <strong>{selectedContact?.name}</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" align="left" gutterBottom>
                Entreprise : <strong>{selectedContact?.company}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" gutterBottom>
                Téléphone : <strong>{selectedContact?.phone}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" gutterBottom>
                Email : <strong>{selectedContact?.email}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" gutterBottom>
                Message : <strong>{selectedContact?.content}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" gutterBottom>
                Date : <strong>{selectedContact?.createdAt}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  onClick={handleCloseDialogDetails}
                  variant="outlined"
                  color="error"
                  startIcon={<ArrowBackRounded />}
                >
                  Revenir à la liste
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
