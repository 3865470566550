import { otherTypes } from "app/redux/types";

const {
  GET_COLORS_RALS_FAILURE,
  GET_COLORS_RALS_REQUEST,
  GET_COLORS_RALS_SUCCESS,

  GET_WILAYAS_FAILURE,
  GET_WILAYAS_REQUEST,
  GET_WILAYAS_SUCCESS,
} = otherTypes;

const initState = {
  colorsRals: [],
  wilayas: [],

  loading_colorsRals: false,
  loading_wilayas: false,

  error_colorsRals: null,
  error_wilayas: null,
};

const otherReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_COLORS_RALS_REQUEST:
      return {
        ...state,
        loading_colorsRals: true,
        error_colorsRals: null,
      };
    case GET_COLORS_RALS_FAILURE:
      return {
        ...state,
        loading_colorsRals: false,
        error_colorsRals: action.payload,
      };
    case GET_COLORS_RALS_SUCCESS:
      return {
        ...state,
        colorsRals: action.payload,
        loading_colorsRals: false,
        error_colorsRals: null,
      };
    case GET_WILAYAS_REQUEST:
      return {
        ...state,
        loading_wilayas: true,
        error_wilayas: null,
      };

    case GET_WILAYAS_FAILURE:
      return {
        ...state,
        loading_wilayas: false,
        error_wilayas: action.payload,
      };
    case GET_WILAYAS_SUCCESS:
      return {
        ...state,
        wilayas: action.payload,
        loading_wilayas: false,
        error_wilayas: null,
      };

    default:
      return state;
  }
};

export default otherReducer;
