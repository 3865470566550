import axios from "axios";
import { redirect } from "app/helpers/historyDom";
import { scopes } from "app/routes/routeList";

/* creating custom axios  */
const axiosInterceptor = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("ses_token")}`,
  },
});

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${localStorage.getItem("ses_token")}`;
  return request;
};

const responseHandler = (response) => {
  if (response.status === 401) {
    localStorage.removeItem("ses_token");
    redirect(scopes.guest.signIn);
  }
  return response;
};

const errorHandler = (error) => {
  if (error.status === 401) {
    localStorage.removeItem("ses_token");
    redirect(scopes.guest.signIn);
  }
  return Promise.reject(error);
};

axiosInterceptor.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosInterceptor.interceptors.response.use(
  (request) => responseHandler(request),
  (error) => errorHandler(error)
);

export default axiosInterceptor;
