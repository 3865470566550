import sesblueversion from "assets/svg/seslogo.svg";
import seswhiteversion from "assets/svg/FrameSESWHITE.svg";
import whiteToles from "assets/svg/FENNECtoles.svg";
import notfound from "assets/svg/notfound.svg";

import content_website from "assets/json/ses_content.json";

export const storyset = {
  notfound: notfound,
};

export const logo = {
  blueSes: sesblueversion,
  whiteSes: seswhiteversion,
  whiteToles: whiteToles,
};

export const ses_content = content_website;
