import {
  endpoint_get_work,
  endpoint_get_work_details,
  endpoint_post_work,
  endpoint_delete_work,
  endpoint_put_work,
} from "app/API/config";
import axiosInterceptor from "app/helpers/interceptor";
import { format } from "date-fns";
import { worksTypes } from "app/redux/types";

const {
  DELETE_WORK_FAILURE,
  DELETE_WORK_REQUEST,
  DELETE_WORK_SUCCESS,
  EDIT_WORK_FAILURE,
  EDIT_WORK_REQUEST,
  EDIT_WORK_SUCCESS,
  GET_WORKS_FAILURE,
  GET_WORKS_REQUEST,
  GET_WORKS_SUCCESS,
  GET_WORK_DETAILS_FAILURE,
  GET_WORK_DETAILS_REQUEST,
  GET_WORK_DETAILS_SUCCESS,
  POST_WORK_FAILURE,
  POST_WORK_REQUEST,
  POST_WORK_SUCCESS,
} = worksTypes;

export const getWorks = () => async (dispatch) => {
  dispatch({ type: GET_WORKS_REQUEST });
  const config = {
    url: endpoint_get_work,
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: GET_WORKS_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_WORKS_FAILURE, payload: error });
  }
};

export const getWorkDetails = (id) => async (dispatch) => {
  dispatch({ type: GET_WORK_DETAILS_REQUEST });
  const config = {
    url: endpoint_get_work_details.replace(":id", id),
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({
      type: GET_WORK_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_WORK_DETAILS_FAILURE, payload: error });
  }
};

export const deleteWork = (id) => async (dispatch) => {
  dispatch({ type: DELETE_WORK_REQUEST });
  const config = {
    url: endpoint_delete_work.replace(":id", id),
    method: "DELETE",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: DELETE_WORK_SUCCESS, payload: id, response: response });
  } catch (error) {
    dispatch({ type: DELETE_WORK_FAILURE, payload: error });
  }
};

export const postWork = (work, closeDialog) => async (dispatch) => {
  dispatch({ type: POST_WORK_REQUEST });
  const formData = new FormData();

  Object.keys(work).forEach((key) => {
    if (work[key] !== null) {
      if (work[key] instanceof Date) {
        formData.append(key, format(work[key], "yyyy-MM-dd"));
      } else if (Array.isArray(work[key])) {
        work[key].forEach((item) => {
          formData.append(`${key}`, item);
        });
      } else {
        formData.append(key, work[key]);
      }
    }
  });
  const config = {
    url: endpoint_post_work,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: POST_WORK_SUCCESS, payload: response.data.data });
    closeDialog();
  } catch (error) {
    dispatch({ type: POST_WORK_FAILURE, payload: error });
  }
};

export const editWork =
  (work, selectedWork, closeDialog) => async (dispatch) => {
    dispatch({ type: EDIT_WORK_REQUEST });
    const formData = new FormData();

    Object.keys(work).forEach((key) => {
      if (work[key] !== null && key !== "id") {
        if (work[key] instanceof Date) {
          formData.append(key, format(work[key], "yyyy-MM-dd"));
        } else if (Array.isArray(work[key])) {
          work[key].forEach((item) => {
            formData.append(`${key}`, item);
          });
        } else {
          formData.append(key, work[key]);
        }
      }
    });
    const config = {
      url: endpoint_put_work.replace(":id", selectedWork?.id),
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const response = await axiosInterceptor(config);
      dispatch({
        type: EDIT_WORK_SUCCESS,
        payload: { ...response.data.data, id: selectedWork.id },
      });
      dispatch(getWorks());
      closeDialog();
    } catch (error) {
      dispatch({ type: EDIT_WORK_FAILURE, payload: error });
    }
  };
