import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  AddRounded,
  CloseRounded,
  DashboardRounded,
  DeleteRounded,
  GroupsRounded,
  LoupeRounded,
  SaveRounded,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { scopes } from "app/routes/routeList";
import CardDashPaper from "components/cards/CardDashPaper";
import BreadCrumbsNav from "components/navigation/BreadCrumbsNav";
import Title from "components/Text/Title";
import CustomNoDataFound from "utils/CustomNoDataFound";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClient,
  editClient,
  getClientDetails,
  getClients,
  postClient,
} from "app/redux/actions/clientsActions";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "theme/dropZoneTheme";
import { LoadingButton } from "@mui/lab";

const Clients = () => {
  const clientState = useSelector((state) => state.clientState);

  const { clients, loading_clients } = clientState;

  const [openDialogPost, setopenDialogPost] = useState(false);
  const [openDialogDetails, setopenDialogDetails] = useState(false);
  const [selectedClient, setselectedClient] = useState({});

  const [page, setpage] = useState(1);
  const cardPerPage = 8;

  const handleChangePage = (event, newPage) => {
    setpage(newPage);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  const navigationBearcrumbs = [
    {
      label: "Tableau de bord",
      path: scopes.admin.home,

      icon: <DashboardRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: false,
    },
    {
      label: "Clients",
      path: scopes.admin.clients,

      icon: <GroupsRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: true,
    },
  ];

  const deleteRow = (params) => {
    dispatch(deleteClient(params.id));
  };

  const handleOpenDialogDetails = (params) => {
    setselectedClient(params);
    setopenDialogDetails(true);
  };

  const handleOpenDialogPost = () => {
    setopenDialogPost(true);
  };

  const handleCloseDialogDetails = () => {
    setselectedClient({});
    setopenDialogDetails(false);
  };
  const handleCloseDialogPost = () => {
    setopenDialogPost(false);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BreadCrumbsNav navigation={navigationBearcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "flex-end",
              },
              width: "100%",
            }}
          >
            <Button
              endIcon={<AddRounded />}
              variant="text"
              color="info"
              onClick={handleOpenDialogPost}
            >
              Ajouter un nouveau client
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <CardDashPaper>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Title
                title="Clients"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              />

              {loading_clients ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    py: 10,
                    px: 2,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : clients.length > 0 ? (
                <Box
                  sx={{
                    px: 2,
                    py: 2,
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={4}
                  >
                    {
                      //order by order
                      clients
                        ?.sort((a, b) => a.order - b.order)
                        ?.slice((page - 1) * cardPerPage, page * cardPerPage)
                        ?.map((client, index) => (
                          <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Card
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  borderRadius: 2,
                                  width: "100%",
                                  height: "100%",
                                  // maxWidth: 300,
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  alt={client.image.name}
                                  height={200}
                                  image={client.image.url}
                                />
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                  >
                                    {client.name}
                                  </Typography>
                                </CardContent>
                                <CardActions>
                                  <Tooltip title="Supprimer">
                                    <IconButton
                                      size="small"
                                      onClick={() => deleteRow(client)}
                                      color="error"
                                    >
                                      <DeleteRounded />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Voir les détails / Modifier">
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleOpenDialogDetails(client)
                                      }
                                      color="info"
                                    >
                                      <LoupeRounded />
                                    </IconButton>
                                  </Tooltip>
                                </CardActions>
                              </Card>
                            </Box>
                          </Grid>
                        ))
                    }
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 2,
                          width: "100%",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(clients?.length / cardPerPage)}
                          page={page}
                          onChange={handleChangePage}
                          variant="outlined"
                          color="primary"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    py: 10,
                  }}
                >
                  <CustomNoDataFound />
                </Box>
              )}
            </Stack>
          </CardDashPaper>
        </Grid>
      </Grid>
      <DialogClientsPost
        open={openDialogPost}
        handleClose={handleCloseDialogPost}
        title="Ajouter un nouveau client"
      />
      <DialogClientDetails
        open={openDialogDetails}
        handleClose={handleCloseDialogDetails}
        title="Détails du client"
        selectedRow={selectedClient}
      />
    </Fragment>
  );
};

export default Clients;

const DialogClientDetails = ({ open, handleClose, title, selectedRow }) => {
  const clientState = useSelector((state) => state.clientState);
  const { loading_clients, loading_clients_details, client_detail } =
    clientState; //client_detail , clients

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRow.id !== undefined) {
      dispatch(getClientDetails(selectedRow?.id));
    }
  }, [dispatch, selectedRow]);

  const fileTypesImages = {
    "image/*": [".jpeg", ".png", ".jpg"],
  };

  let {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    inputRef,
    // rootRef,
  } = useDropzone({
    accept: fileTypesImages,
    // maxFiles: 1,
    multiple: false,
    onDropAccepted: (files) => {
      formik.setFieldValue("image", files[0]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const validationSchema = yup.object({
    name: yup
      .string("Veuillez entrer un nom")
      .required("le nom est requis")
      .max(150, "le nom ne doit pas dépasser 150 caractères"),
    order: yup
      .number("Veuillez entrer un ordre")
      .required("le ordre est requis"),
    image: yup.mixed().required("veuillez entrer une image"),
    // order: yup
    //   .number("Veuillez entrer un ordre")
    //   .required("le ordre est requis"),
  });

  const formFields = useMemo(() => {
    return [
      {
        name: "image",
        id: "image",
        label: "Image",
        type: "file",
        initialValue: selectedRow?.image,
      },
      {
        name: "name",
        id: "name",
        label: "Nom",
        type: "string",
        initialValue: selectedRow?.name,
      },
      {
        name: "order",
        id: "order",
        label: "Ordre",
        type: "number",
        initialValue: selectedRow?.order,
      },
    ];
  }, [selectedRow]);

  const initialValues = useMemo(() => {
    return formFields.reduce((acc, field) => {
      acc[field?.name] = field?.initialValue;
      return acc;
    }, {});
  }, [formFields]);

  let formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // dispatch(postClient(values, handleClose));
      if (selectedRow?.id !== undefined) {
        dispatch(
          editClient(
            {
              id: selectedRow?.id,
              ...values,
            },
            closeAndResetForm
          )
        );
      }
    },
  });

  const closeAndResetForm = () => {
    formik.resetForm();
    removeAllFiles();
    handleClose();
  };

  const removeAllFiles = () => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      inputRef.current.value = "";
    }
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={closeAndResetForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {loading_clients_details ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              p: 4,
            }}
          >
            <CircularProgress />
          </Box>
        ) : client_detail !== null ? (
          <Box
            component="form"
            // noValidate
            sx={{ mt: 1 }}
            onSubmit={formik.handleSubmit}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              {formFields.map((field) => (
                <Grid item key={field?.id} xs={12}>
                  {/* 💣💣💣 */}
                  {field.type === "file" ? (
                    <section className="container">
                      <div
                        {...getRootProps({ style })}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box
                          component="div"
                          {...getRootProps({ className: "dropzone" })}
                          sx={{
                            p: "30px",
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={2}
                          >
                            {field.initialValue !== null &&
                              field.initialValue !== "" &&
                              acceptedFiles.length === 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Avatar
                                    src={field.initialValue?.url}
                                    alt={field.initialValue?.name}
                                    variant="rounded"
                                    sx={{ width: 80, height: 80, p: 2 }}
                                  />
                                </Box>
                              )}
                            {acceptedFiles.length > 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {acceptedFiles?.map((file, index) => {
                                  return (
                                    <Avatar
                                      key={index}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                      variant="rounded"
                                      sx={{ width: 80, height: 80, p: 2 }}
                                    />
                                  );
                                })}
                              </Box>
                            )}
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <input {...getInputProps()} />
                              <p>
                                Glisser et déposer une image ici, ou cliquer ci
                                dessous pour sélectionner une image
                              </p>

                              <em>
                                {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                              </em>
                            </div>
                          </Stack>
                          <Box
                            sx={{
                              my: 2,
                            }}
                          />
                          <aside>
                            {fileRejections.length > 0 && (
                              <>
                                <h4>image refusé</h4>
                                <ul>
                                  {fileRejections.map(({ file, errors }) => (
                                    <li key={file.path}>
                                      {file.path} - {file.size} bytes
                                      <ul>
                                        {errors.map((e) => (
                                          <li key={e.code}>{e.message}</li>
                                        ))}
                                      </ul>
                                    </li>
                                  ))}
                                </ul>{" "}
                              </>
                            )}
                          </aside>
                        </Box>
                      </div>
                      {formik.touched[field.name] &&
                        Boolean(formik.errors[field.name]) && (
                          <Alert
                            severity="error"
                            variant="outlined"
                            sx={{
                              mt: 2,
                            }}
                          >
                            {formik.touched[field.name] &&
                              formik.errors[field.name]}
                          </Alert>
                        )}
                    </section>
                  ) : field.type === "string" ? (
                    <TextField
                      fullWidth
                      id={field.id}
                      name={field.name}
                      label={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.name] &&
                        Boolean(formik.errors[field.name])
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  ) : field.type === "number" ? (
                    <TextField
                      type="number"
                      fullWidth
                      id={field.id}
                      name={field.name}
                      label={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.name] &&
                        Boolean(formik.errors[field.name])
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  ) : null}
                </Grid>
              ))}
              {loading_clients && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    onClick={closeAndResetForm}
                    variant="outlined"
                    color="error"
                    endIcon={<CloseRounded />}
                  >
                    Annuler
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SaveRounded />}
                    loading={loading_clients || loading_clients_details}
                  >
                    Enregistrer
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <CustomNoDataFound />
        )}
      </DialogContent>
    </Dialog>
  );
};

const DialogClientsPost = ({ open, handleClose, title }) => {
  const clientState = useSelector((state) => state.clientState);

  const { loading_clients, loading_clients_details } = clientState; //client_detail , clients

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const fileTypesImages = {
    "image/*": [".jpeg", ".png", ".jpg"],
  };

  let {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    inputRef,
    // rootRef,
  } = useDropzone({
    accept: fileTypesImages,
    // maxFiles: 1,
    multiple: false,
    onDropAccepted: (files) => {
      formik.setFieldValue("image", files[0]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const validationSchema = yup.object({
    name: yup
      .string("Veuillez entrer un nom")
      .required("le nom est requis")
      .max(150, "le nom ne doit pas dépasser 150 caractères"),
    image: yup.mixed().required("veuillez entrer une image"),
    // order: yup
    //   .number("Veuillez entrer un ordre")
    //   .required("le ordre est requis"),
  });

  const formFields = useMemo(() => {
    return [
      {
        name: "image",
        id: "image",
        label: "Image",
        type: "file",
        initialValue: "",
      },
      {
        name: "name",
        id: "name",
        label: "Nom",
        type: "string",
        initialValue: "",
      },
    ];
  }, []);

  const initialValues = useMemo(() => {
    return formFields.reduce((acc, field) => {
      acc[field.name] = field.initialValue;
      return acc;
    }, {});
  }, [formFields]);

  let formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(postClient(values, handleClose));
    },
  });

  const closeAndResetForm = () => {
    formik.resetForm();
    removeAllFiles();
    handleClose();
  };

  const removeAllFiles = () => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      inputRef.current.value = "";
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={closeAndResetForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          // noValidate
          sx={{ mt: 1 }}
          onSubmit={formik.handleSubmit}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
          >
            {formFields.map((field) => (
              <Grid item key={field.id} xs={12}>
                {field.type === "file" ? (
                  <section className="container">
                    <div
                      {...getRootProps({ style })}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Box
                        component="div"
                        {...getRootProps({ className: "dropzone" })}
                        sx={{
                          p: "30px",
                        }}
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                          spacing={2}
                        >
                          {field.initialValue !== null &&
                            field.initialValue !== "" &&
                            acceptedFiles.length === 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Avatar
                                  src={field.initialValue.url}
                                  alt={field.initialValue.name}
                                  variant="rounded"
                                  sx={{ width: 80, height: 80, p: 2 }}
                                />
                              </Box>
                            )}
                          {acceptedFiles.length > 0 && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {acceptedFiles?.map((file, index) => {
                                return (
                                  <Avatar
                                    key={index}
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                    variant="rounded"
                                    sx={{ width: 80, height: 80, p: 2 }}
                                  />
                                );
                              })}
                            </Box>
                          )}
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <input {...getInputProps()} />
                            <p>
                              {/* Drag 'n' drop some files here, or click to select files */}
                              {/* in fr */}
                              Glisser et déposer une image ici, ou cliquer pour
                              en sélectionner une
                            </p>
                            <em>
                              {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                            </em>
                          </div>
                        </Stack>
                        <Box
                          sx={{
                            my: 2,
                          }}
                        />
                        <aside>
                          {fileRejections.length > 0 && (
                            <>
                              <h4>image refusé</h4>
                              <ul>
                                {fileRejections.map(({ file, errors }) => (
                                  <li key={file.path}>
                                    {file.path} - {file.size} bytes
                                    <ul>
                                      {errors.map((e) => (
                                        <li key={e.code}>{e.message}</li>
                                      ))}
                                    </ul>
                                  </li>
                                ))}
                              </ul>{" "}
                            </>
                          )}
                        </aside>
                      </Box>
                    </div>
                    {formik.touched[field.name] &&
                      Boolean(formik.errors[field.name]) && (
                        <Alert
                          severity="error"
                          variant="outlined"
                          sx={{
                            mt: 2,
                          }}
                        >
                          {formik.touched[field.name] &&
                            formik.errors[field.name]}
                        </Alert>
                      )}
                  </section>
                ) : field.type === "string" ? (
                  <TextField
                    fullWidth
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                ) : field.type === "number" ? (
                  <TextField
                    type="number"
                    fullWidth
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                ) : null}
              </Grid>
            ))}
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  onClick={closeAndResetForm}
                  variant="outlined"
                  color="error"
                  endIcon={<CloseRounded />}
                >
                  Annuler
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<SaveRounded />}
                  loading={loading_clients || loading_clients_details}
                >
                  Enregistrer
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
