import { contactTypes } from "app/redux/types";

const {
  GET_CONTACT_REQUEST,
  GET_CONTACT_FAILURE,
  GET_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
} = contactTypes;

const initState = {
  contacts: [],
  loading_contact: false,
  error_contact: null,
};

const contactReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CONTACT_REQUEST:
    case DELETE_CONTACT_REQUEST:
      return {
        ...state,
        loading_contact: true,
        error_contact: null,
      };
    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        loading_contact: false,
        error_contact: null,
      };
    case GET_CONTACT_FAILURE:
    case DELETE_CONTACT_FAILURE:
      return {
        ...state,
        loading_contact: false,
        error_contact: action.payload,
      };
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.filter(
          (contact) => contact.id.toString() !== action.payload.toString()
        ),
        loading_contact: false,
        error_contact: null,
      };

    default:
      return state;
  }
};

export default contactReducer;
