export const headerTypes = {
  GET_HEADER_REQUEST: "GET_HEADER_REQUEST",
  GET_HEADER_SUCCESS: "GET_HEADER_SUCCESS",
  GET_HEADER_FAILURE: "GET_HEADER_FAILURE",
};

export const products_clients_types = {
  GET_PRODUCT_CLIENT_REQUEST: "GET_PRODUCT_REQUEST",
  GET_PRODUCT_CLIENT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_CLIENT_FAILURE: "GET_PRODUCT_FAILURE",

  GET_PRODUCT_CLIENT_WITH_OTHER_REQUEST: "GET_PRODUCT_WITH_OTHER_REQUEST",
  GET_PRODUCT_CLIENT_WITH_OTHER_SUCCESS: "GET_PRODUCT_WITH_OTHER_SUCCESS",
  GET_PRODUCT_CLIENT_WITH_OTHER_FAILURE: "GET_PRODUCT_WITH_OTHER_FAILURE",

  GET_PRODUCT_DETAILS_CS_REQUEST: "GET_PRODUCT_DETAILS_CS_REQUEST",
  GET_PRODUCT_DETAILS_CS_SUCCESS: "GET_PRODUCT_DETAILS_CS_SUCCESS",
  GET_PRODUCT_DETAILS_CS_FAILURE: "GET_PRODUCT_DETAILS_CS_FAILURE",

  POST_PRODUCT_CLIENT_REQUEST: "POST_PRODUCT_REQUEST",
  POST_PRODUCT_CLIENT_SUCCESS: "POST_PRODUCT_SUCCESS",
  POST_PRODUCT_CLIENT_FAILURE: "POST_PRODUCT_FAILURE",
};

export const memberTypesClient = {
  GET_MEMBER_CLIENT_REQUEST: "GET_MEMBER_CLIENT_REQUEST",
  GET_MEMBER_CLIENT_SUCCESS: "GET_MEMBER_CLIENT_SUCCESS",
  GET_MEMBER_CLIENT_FAILURE: "GET_MEMBER_CLIENT_FAILURE",
};

export const clientCSTypes = {
  GET_CLIENT_CS_REQUEST: "GET_CLIENT_CS_REQUEST",
  GET_CLIENT_CS_SUCCESS: "GET_CLIENT_CS_SUCCESS",
  GET_CLIENT_CS_FAILURE: "GET_CLIENT_CS_FAILURE",
};

export const blogCSTypes = {
  GET_BLOG_CS_REQUEST: "GET_BLOG_CS_REQUEST",
  GET_BLOG_CS_SUCCESS: "GET_BLOG_CS_SUCCESS",
  GET_BLOG_CS_FAILURE: "GET_BLOG_CS_FAILURE",

  GET_BLOG_DETAILS_CS_REQUEST: "GET_BLOG_DETAILS_CS_REQUEST",
  GET_BLOG_DETAILS_CS_SUCCESS: "GET_BLOG_DETAILS_CS_SUCCESS",
  GET_BLOG_DETAILS_CS_FAILURE: "GET_BLOG_DETAILS_CS_FAILURE",
};
