import { endpoint_delete_media } from "app/API/config";
import axiosInterceptor from "app/helpers/interceptor";
import { useSnack } from "app/redux/actions/snacksActions";
import { productTypes } from "app/redux/types";

const {
  RELOAD_CONTENT_FAILURE,
  RELOAD_CONTENT_REQUEST,
  RELOAD_CONTENT_SUCCESS,
} = productTypes;

export const deleteMedia = (id) => async (dispatch) => {
  dispatch({ type: RELOAD_CONTENT_REQUEST });
  const config = {
    url: endpoint_delete_media.replace(":id", id),
    method: "DELETE",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch(useSnack("Media supprimé avec succès", "success"));
    dispatch({ type: RELOAD_CONTENT_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: RELOAD_CONTENT_FAILURE,
      payload: error,
    });
  }
};
