import { endpoint_delete_contact, endpoint_get_contact } from "app/API/config";
import axiosInterceptor from "app/helpers/interceptor";
import { contactTypes } from "app/redux/types";

const {
  GET_CONTACT_REQUEST,
  GET_CONTACT_FAILURE,
  GET_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
} = contactTypes;

export const getContactList = () => async (dispatch) => {
  dispatch({ type: GET_CONTACT_REQUEST });
  const config = {
    url: endpoint_get_contact,
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: GET_CONTACT_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_CONTACT_FAILURE, payload: error });
  }
};

export const deleteContact = (id) => async (dispatch) => {
  dispatch({ type: DELETE_CONTACT_REQUEST });
  const config = {
    url: endpoint_delete_contact.replace(":id", id),
    method: "DELETE",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: DELETE_CONTACT_SUCCESS, payload: id, response: response });
  } catch (error) {
    dispatch({ type: DELETE_CONTACT_FAILURE, payload: error });
  }
};
