import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { removeSnackbar } from "app/redux/actions/snacksActions";

let displayed = [];

const Notifier = () => {
  const snacks_state = useSelector((state) => state.snacksState);

  const { snacks } = snacks_state || [];

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    snacks?.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        /* dismissed snackbar using notistack */
        closeSnackbar(key);
        return;
      }

      //do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      //display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          //remove this snackbar from redux store
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });
      //keep track snackbars
      storeDisplayed(key);
    });
  }, [snacks, enqueueSnackbar, closeSnackbar, dispatch]);

  return null;
};

export default Notifier;
