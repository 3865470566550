import NotFound from "pages/NotFound";
import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { adminList, guestList } from "app/routes/routeList";
import { useSelector } from "react-redux";

const Routes = () => {
  const authState = useSelector((state) => state.authState);
  const { token } = authState;
  return (
    <Switch>
      {/* 💣 */}
      {token !== null &&
        adminList.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}

      {guestList.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}

      <Route
        path="*"
        component={() => (
          <Suspense fallback="">
            <NotFound />
          </Suspense>
        )}
      />
    </Switch>
  );
};

export default Routes;
