import {
  endpoint_delete_client,
  endpoint_edit_client,
  endpoint_get_clients,
  endpoint_get_clients_details,
  endpoint_post_client,
} from "app/API/config";
import axiosInterceptor from "app/helpers/interceptor";
import { clientsType } from "app/redux/types";
import { format } from "date-fns";

const {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,

  GET_CLIENT_DETAILS_FAILURE,
  GET_CLIENT_DETAILS_REQUEST,
  GET_CLIENT_DETAILS_SUCCESS,

  POST_CLIENT_FAILURE,
  POST_CLIENT_REQUEST,
  POST_CLIENT_SUCCESS,

  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,

  EDIT_CLIENT_FAILURE,
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_SUCCESS,
} = clientsType;

export const getClients = () => async (dispatch) => {
  dispatch({ type: GET_CLIENTS_REQUEST });
  const config = {
    url: endpoint_get_clients,
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: GET_CLIENTS_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_CLIENTS_FAILURE, payload: error });
  }
};

export const getClientDetails = (id) => async (dispatch) => {
  dispatch({ type: GET_CLIENT_DETAILS_REQUEST });
  const config = {
    url: endpoint_get_clients_details.replace(":id", id),
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: GET_CLIENT_DETAILS_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_CLIENT_DETAILS_FAILURE, payload: error });
  }
};

export const postClient = (client, closeDialog) => async (dispatch) => {
  dispatch({ type: POST_CLIENT_REQUEST });
  const formData = new FormData();

  Object.keys(client).forEach((key) => {
    if (
      client[key] !== null &&
      client[key] !== undefined &&
      client[key] !== ""
    ) {
      if (client[key] instanceof Date) {
        formData.append(key, format(client[key], "yyyy-MM-dd"));
      } else {
        formData.append(key, client[key]);
      }
    }
  });

  const config = {
    url: endpoint_post_client,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };

  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: POST_CLIENT_SUCCESS, payload: response.data.data });
    closeDialog();
  } catch (error) {
    dispatch({ type: POST_CLIENT_FAILURE, payload: error });
  }
};

export const deleteClient = (id) => async (dispatch) => {
  dispatch({ type: DELETE_CLIENT_REQUEST });
  const config = {
    url: endpoint_delete_client.replace(":id", id),
    method: "DELETE",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: DELETE_CLIENT_SUCCESS, payload: id, response: response });
  } catch (error) {
    dispatch({ type: DELETE_CLIENT_FAILURE, payload: error });
  }
};

export const editClient = (client, closeDialog) => async (dispatch) => {
  dispatch({ type: EDIT_CLIENT_REQUEST });
  const formData = new FormData();

  Object.keys(client).forEach((key) => {
    if (
      client[key] !== null &&
      key !== "id" &&
      client[key] !== undefined &&
      client[key] !== ""
    ) {
      if (client[key] instanceof Date) {
        formData.append(key, format(client[key], "yyyy-MM-dd"));
      } else if (
        key === "image" &&
        client[key].url !== undefined &&
        client[key].url !== null &&
        client[key].url !== ""
      ) {
        // do nothing
        return;
      } else {
        formData.append(key, client[key]);
      }
    }
  });

  const config = {
    url: endpoint_edit_client.replace(":id", client.id),
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };

  try {
    const response = await axiosInterceptor(config);
    dispatch({
      type: EDIT_CLIENT_SUCCESS,
      payload: response.data.data,
    });
    dispatch(getClients());
    closeDialog();
  } catch (error) {
    dispatch({ type: EDIT_CLIENT_FAILURE, payload: error });
  }
};
