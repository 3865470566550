import React, { useEffect, useState, Fragment, useMemo } from "react";
import {
  AddRounded,
  DashboardRounded,
  DeleteRounded,
  GroupRounded,
  LoupeRounded,
  CloseRounded,
  SaveRounded,
} from "@mui/icons-material";
import { updateMember } from "app/redux/actions/membersActions";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import CustomNoDataFound from "utils/CustomNoDataFound";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Alert,
  TextField,
  LinearProgress,
  // DialogContentText,
  // DialogActions,
} from "@mui/material";
import { scopes } from "app/routes/routeList";
import CardDashPaper from "components/cards/CardDashPaper";
import BreadCrumbsNav from "components/navigation/BreadCrumbsNav";
import Title from "components/Text/Title";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "theme/dropZoneTheme";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { GRID_DEFAULT_LOCALE_TEXT } from "utils/localtextGridTable";
import CustomNoRowsOverlay from "utils/CustomNoRowsOverlay";
import CustomLoadingOverlay from "utils/CustomLoadingOverlay";
import CustomToolbar from "utils/CustomToolBar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMember,
  getMemberDetail,
  getMembers,
  postMember,
} from "app/redux/actions/membersActions";

import * as yup from "yup";

import { LoadingButton } from "@mui/lab";
// import DialogDetails from "components/Dialog/DialogDetails";

const Equipe = () => {
  const membersState = useSelector((state) => state.membersState);
  const { members, loading_members } = membersState;

  const [openDialogPost, setopenDialogPost] = useState(false);

  const [openDialogDetails, setopenDialogDetails] = useState(false);
  const [selectedMember, setselectedMember] = useState({});

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    order: false,
    createdAt: false,
    image: true,
    email: true,
    phone: true,
    title: true,
    name: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMembers());
  }, [dispatch]);

  const columns = [
    {
      headerName: "Actions 👇",
      field: "actions",
      type: "actions",
      hideable: false,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteRounded color="error" />}
          label="Supprimer"
          onClick={() => deleteRow(params)}
        />,
        <GridActionsCellItem
          icon={<LoupeRounded color="info" />}
          label="Voir les détails"
          onClick={() => handleOpenDialogDetails(params)}
        />,
      ],
    },
    {
      field: "image",
      headerName: "Avatar",
      // flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <Avatar alt={params?.value?.name} src={params?.value?.url} />;
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "name",
      headerName: "Nom",
      // flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "title",
      headerName: "Titre",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "phone",
      headerName: "Téléphone",
      // flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Date",
      type: "date",
      // flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "order",
      headerName: "Ordre",
      // flex: 1,
      minWidth: 150,
      // sortable: false,
    },
  ];

  const navigationBearcrumbs = [
    {
      label: "Tableau de bord",
      path: scopes.admin.home,

      icon: <DashboardRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: false,
    },
    {
      label: "Equipe",
      path: scopes.admin.equipe,

      icon: <GroupRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: true,
    },
  ];

  const deleteRow = (params) => {
    dispatch(deleteMember(params.row.id));
  };

  const handleOpenDialogDetails = (params) => {
    setselectedMember(params.row);
    setopenDialogDetails(true);
  };

  const handleCloseDialogDetails = () => {
    setopenDialogDetails(false);
  };

  const handleCloseDialogPost = () => {
    setopenDialogPost(false);
  };

  const handleOpenDialogPost = () => {
    setopenDialogPost(true);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BreadCrumbsNav navigation={navigationBearcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "flex-end",
              },
              width: "100%",
            }}
          >
            <Button
              endIcon={<AddRounded />}
              variant="text"
              color="info"
              onClick={handleOpenDialogPost}
            >
              Ajouter un nouveau membre
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CardDashPaper>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Title
                title="Equipe"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              />
              <div style={{ display: "flex", height: 500, width: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={members}
                    columns={columns}
                    localeText={GRID_DEFAULT_LOCALE_TEXT}
                    loading={loading_members}
                    aria-label="members_table"
                    aria-labelledby="members_table_id"
                    disableSelectionOnClick
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "order", sort: "asc" }],
                      },
                    }}
                    componentsProps={{
                      toolbar: { csvOptions: { disableToolbarButton: true } },
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setColumnVisibilityModel(newModel)
                    }
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      LoadingOverlay: CustomLoadingOverlay,
                      Toolbar: CustomToolbar,
                    }}
                  />
                </div>
              </div>
            </Stack>
          </CardDashPaper>
        </Grid>
      </Grid>

      {/* DIALOG FORM TO POST NEW ROW */}
      <DialogMemberPost
        open={openDialogPost}
        handleClose={handleCloseDialogPost}
        title="Ajouter un nouveau membre"
      />

      {/* DIALOG DETAILS / EDIT ROW 💣 */}
      {/* <DialogDetails
        title="Détails du membre"
        open={openDialogDetails}
        handleClose={handleCloseDialogDetails}
        handleGetDetails={getMemberDetail}
        selectedRow={selectedMember}
        multipleFiles={false}
        // hasFiles={true}
      /> */}
      <DialogMemberUpdate
        open={openDialogDetails}
        handleClose={handleCloseDialogDetails}
        title="Détails du membre"
        selectedRow={selectedMember}
      />
    </Fragment>
  );
};

export default Equipe;

const DialogMemberPost = ({ open, handleClose, title }) => {
  const membersState = useSelector((state) => state.membersState);
  const { loading_members } = membersState;

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const fileTypesImages = {
    "image/*": [".jpeg", ".png", ".jpg"],
  };
  let {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    inputRef,
    // rootRef,
  } = useDropzone({
    accept: fileTypesImages,
    // maxFiles: 1,
    multiple: false,
    onDropAccepted: (files) => {
      formik.setFieldValue("image", files[0]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const /* A Yup object schema. It is used to validate the form fields. */
    validationSchema = yup.object({
      name: yup
        .string("Veuillez entrer un nom")
        .required("le nom est requis")
        .max(150, "le nom ne doit pas dépasser 150 caractères"),
      title: yup
        .string("Veuillez entrer un titre")
        .required("le titre est requis")
        .max(150, "le titre doit faire 150 caractères maximum"),
      phone: yup
        .string("Veuillez entrer un numéro de téléphone")
        // .required("le numéro de téléphone est requis")
        .matches(
          /^(00213|\+213|0)(5|6|7)[0-9]{8}$/,
          "Numéro de téléphone invalide"
        ),
      email: yup
        .string("Veuillez entrer un email")
        .email("Veuillez entrer un email valide"),
      // .required("l'email est requis"),
      image: yup.mixed().required("veuillez entrer une image"),
      // order: yup
      //   .number("Veuillez entrer un ordre")
      //   .required("le ordre est requis"),
    });

  const formFields = useMemo(() => {
    return [
      {
        name: "name",
        id: "name",
        label: "Nom",
        type: "string",
        initialValue: "",
      },
      {
        name: "title",
        id: "title",
        label: "Titre",
        type: "string",
        initialValue: "",
      },
      {
        name: "phone",
        id: "phone",
        label: "Numéro de téléphone",
        type: "string",
        initialValue: "",
      },
      {
        name: "email",
        id: "email",
        label: "Email",
        type: "string",
        initialValue: "",
      },
      {
        name: "image",
        id: "image",
        label: "Image",
        type: "file",
        initialValue: "",
      },
    ];
  }, []);

  const initialValues = useMemo(() => {
    return formFields.reduce((acc, field) => {
      acc[field.name] = field.initialValue;
      return acc;
    }, {});
  }, [formFields]);

  let formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(postMember(values, handleClose));
    },
  });
  const closeAndResetForm = () => {
    formik.resetForm();
    removeAllFiles();
    handleClose();
  };

  const removeAllFiles = () => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      inputRef.current.value = "";
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={closeAndResetForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          // noValidate
          sx={{ mt: 1 }}
          onSubmit={formik.handleSubmit}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
          >
            {formFields.map((field) => (
              <Grid item key={field.id} xs={12}>
                {field.type === "file" ? (
                  <section className="container">
                    <div
                      {...getRootProps({ style })}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Box
                        component="div"
                        {...getRootProps({ className: "dropzone" })}
                        sx={{
                          p: "30px",
                        }}
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                          spacing={2}
                        >
                          {field.initialValue !== null &&
                            field.initialValue !== "" &&
                            acceptedFiles.length === 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Avatar
                                  src={field.initialValue.url}
                                  alt={field.initialValue.name}
                                  variant="rounded"
                                  sx={{ width: 80, height: 80, p: 2 }}
                                />
                              </Box>
                            )}
                          {acceptedFiles.length > 0 && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {acceptedFiles?.map((file, index) => {
                                return (
                                  <Avatar
                                    key={index}
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                    variant="rounded"
                                    sx={{ width: 80, height: 80, p: 2 }}
                                  />
                                );
                              })}
                            </Box>
                          )}
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <input {...getInputProps()} />
                            <p>
                              {/* Drag 'n' drop some files here, or click to select files */}
                              {/* in fr */}
                              Glisser et déposer une image ici, ou cliquer pour
                              en sélectionner une
                            </p>
                            <em>
                              {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                            </em>
                          </div>
                        </Stack>
                        <Box
                          sx={{
                            my: 2,
                          }}
                        />
                        <aside>
                          {fileRejections.length > 0 && (
                            <>
                              <h4>image refusé</h4>
                              <ul>
                                {fileRejections.map(({ file, errors }) => (
                                  <li key={file.path}>
                                    {file.path} - {file.size} bytes
                                    <ul>
                                      {errors.map((e) => (
                                        <li key={e.code}>{e.message}</li>
                                      ))}
                                    </ul>
                                  </li>
                                ))}
                              </ul>{" "}
                            </>
                          )}
                        </aside>
                      </Box>
                    </div>
                    {formik.touched[field.name] &&
                      Boolean(formik.errors[field.name]) && (
                        <Alert
                          severity="error"
                          variant="outlined"
                          sx={{
                            mt: 2,
                          }}
                        >
                          {formik.touched[field.name] &&
                            formik.errors[field.name]}
                        </Alert>
                      )}
                  </section>
                ) : field.type === "string" ? (
                  <TextField
                    fullWidth
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                ) : field.type === "number" ? (
                  <TextField
                    type="number"
                    fullWidth
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                ) : null}
              </Grid>
            ))}
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  onClick={closeAndResetForm}
                  variant="outlined"
                  color="error"
                  endIcon={<CloseRounded />}
                >
                  Annuler
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<SaveRounded />}
                  loading={loading_members}
                >
                  Enregistrer
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const DialogMemberUpdate = ({ open, handleClose, title, selectedRow }) => {
  const membersState = useSelector((state) => state.membersState);
  const { loading_member, member_detail, loading_members } = membersState;

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRow.id !== undefined) {
      dispatch(getMemberDetail(selectedRow?.id));
    }
  }, [dispatch, selectedRow]);

  const validationSchema = yup.object({
    name: yup
      .string("Veuillez entrer un nom")
      .required("le nom est requis")
      .max(150, "le nom ne doit pas dépasser 150 caractères"),
    title: yup
      .string("Veuillez entrer un titre")
      .required("le titre est requis")
      .max(150, "le titre doit faire 150 caractères maximum"),
    phone: yup
      .string("Veuillez entrer un numéro de téléphone")
      // .required("le numéro de téléphone est requis")
      .matches(
        /^(00213|\+213|0)(5|6|7)[0-9]{8}$/,
        "Numéro de téléphone invalide"
      ),
    email: yup
      .string("Veuillez entrer un email")
      .email("Veuillez entrer un email valide"),
    // .required("l'email est requis"),
    image: yup.mixed().required("veuillez entrer une image"),
    order: yup
      .number("Veuillez entrer un ordre")
      .required("le ordre est requis"),
    // order: yup
    //   .number("Veuillez entrer un ordre")
    //   .required("le ordre est requis"),
  });

  const formFields = useMemo(() => {
    return [
      {
        name: "image",
        id: "image",
        label: "Image",
        type: "file",
        initialValue: selectedRow?.image,
      },

      {
        name: "name",
        id: "name",
        label: "Nom",
        type: "string",
        initialValue: selectedRow?.name,
      },
      {
        name: "title",
        id: "title",
        label: "Titre",
        type: "string",
        initialValue: selectedRow?.title,
      },
      {
        name: "phone",
        id: "phone",
        label: "Numéro de téléphone",
        type: "string",
        initialValue: selectedRow?.phone,
      },
      {
        name: "email",
        id: "email",
        label: "Email",
        type: "string",
        initialValue: selectedRow?.email,
      },
      {
        name: "order",
        id: "order",
        label: "Ordre",
        type: "number",
        initialValue: selectedRow?.order,
      },
    ];
  }, [selectedRow]);

  const fileTypesImages = {
    "image/*": [".jpeg", ".png", ".jpg"],
  };

  let {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    inputRef,
    // rootRef,
  } = useDropzone({
    accept: fileTypesImages,
    // maxFiles: 1,
    multiple: false,
    onDropAccepted: (files) => {
      formik.setFieldValue("image", files[0]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const initialValues = useMemo(() => {
    return formFields.reduce((acc, field) => {
      acc[field.name] = field.initialValue;
      return acc;
    }, {});
  }, [formFields]);

  let formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // compares values to selectedRow and updates if different
      if (selectedRow.id !== undefined) {
        dispatch(
          updateMember(
            {
              id: selectedRow.id,
              ...values,
            },
            closeAndResetForm //💣
          )
        );
      }
    },
  });

  const closeAndResetForm = () => {
    formik.resetForm();
    removeAllFiles();
    handleClose();
  };

  const removeAllFiles = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    inputRef.current.value = "";
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={closeAndResetForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {loading_member ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              p: 4,
            }}
          >
            <CircularProgress />
          </Box>
        ) : member_detail !== null ? (
          <Box
            component="form"
            // noValidate
            sx={{ mt: 1 }}
            onSubmit={formik.handleSubmit}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              {formFields.map((field) => (
                <Grid item key={field.id} xs={12}>
                  {/* 💣💣💣 */}
                  {field.type === "file" ? (
                    <section className="container">
                      <div
                        {...getRootProps({ style })}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box
                          component="div"
                          {...getRootProps({ className: "dropzone" })}
                          sx={{
                            p: "30px",
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={2}
                          >
                            {field.initialValue !== null &&
                              field.initialValue !== "" &&
                              acceptedFiles.length === 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Avatar
                                    src={field.initialValue.url}
                                    alt={field.initialValue.name}
                                    variant="rounded"
                                    sx={{ width: 80, height: 80, p: 2 }}
                                  />
                                </Box>
                              )}
                            {acceptedFiles.length > 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {acceptedFiles?.map((file, index) => {
                                  return (
                                    <Avatar
                                      key={index}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                      variant="rounded"
                                      sx={{ width: 80, height: 80, p: 2 }}
                                    />
                                  );
                                })}
                              </Box>
                            )}
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <input {...getInputProps()} />
                              <p>
                                {/* Drag 'n' drop some files here, or click to select files */}
                                {/* in fr */}
                                Glisser et déposer une image ici, ou cliquer
                                pour en sélectionner une
                              </p>
                              <em>
                                {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                              </em>
                            </div>
                          </Stack>
                          <Box
                            sx={{
                              my: 2,
                            }}
                          />
                          <aside>
                            {fileRejections.length > 0 && (
                              <>
                                <h4>image refusé</h4>
                                <ul>
                                  {fileRejections.map(({ file, errors }) => (
                                    <li key={file.path}>
                                      {file.path} - {file.size} bytes
                                      <ul>
                                        {errors.map((e) => (
                                          <li key={e.code}>{e.message}</li>
                                        ))}
                                      </ul>
                                    </li>
                                  ))}
                                </ul>{" "}
                              </>
                            )}
                          </aside>
                        </Box>
                      </div>
                      {formik.touched[field.name] &&
                        Boolean(formik.errors[field.name]) && (
                          <Alert
                            severity="error"
                            variant="outlined"
                            sx={{
                              mt: 2,
                            }}
                          >
                            {formik.touched[field.name] &&
                              formik.errors[field.name]}
                          </Alert>
                        )}
                    </section>
                  ) : field.type === "string" ? (
                    <TextField
                      fullWidth
                      id={field.id}
                      name={field.name}
                      label={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.name] &&
                        Boolean(formik.errors[field.name])
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  ) : field.type === "number" ? (
                    <TextField
                      type="number"
                      fullWidth
                      id={field.id}
                      name={field.name}
                      label={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.name] &&
                        Boolean(formik.errors[field.name])
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  ) : null}
                </Grid>
              ))}
              {loading_members && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    onClick={closeAndResetForm}
                    variant="outlined"
                    color="error"
                    endIcon={<CloseRounded />}
                  >
                    Annuler
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SaveRounded />}
                    loading={loading_members || loading_member}
                  >
                    Enregistrer
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <CustomNoDataFound />
        )}
      </DialogContent>
    </Dialog>
  );
};
