export const drawerWidth = 280;

export const direction =
  localStorage.getItem("ses_lang") === "ar" ? "rtl" : "ltr";

/* default theme for MUI components */
export const themeLight = {
  direction: direction, // Both here and <body dir="rtl">
  typography: {
    fontFamily: [
      "Montserrat",
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    mode: "light",
    primary: {
      main: "#1952A9",
      light: "#0075FF",
      dark: "#192436",
    },
    secondary: {
      main: "#29D0C6",
      light: "#BEEAE7",
      contrastText: "#fff",
    },
    // background: {
    //   default: "#E5E5E5",
    //   paper: "#F4F4F4",
    // },
    text: {
      primary: "rgba(51, 51, 51, 1)",
      disabled: "#828282",
    },
  },
  shape: {
    borderRadius: 4,
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
  overrides: {
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
          transform: "translateX(16px)",
          color: "#fff",
          "& + $track": {
            opacity: 1,
            border: "none",
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: "1px solid #bdbdbd",
        backgroundColor: "#fafafa",
        opacity: 1,
        transition:
          "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
    },
    // MuiAppBar: {
    //   colorInherit: {
    //     backgroundColor: "#E5E5E5",
    //     color: "#333333",
    //   },
    // },
    props: {
      // MuiAppBar: {
      //   color: "inherit",
      // },
      MuiTooltip: {
        arrow: true,
      },
    },
  },
};
