import { clientCSTypes } from "app/redux/types_client";

const { GET_CLIENT_CS_FAILURE, GET_CLIENT_CS_REQUEST, GET_CLIENT_CS_SUCCESS } =
  clientCSTypes;

const initState = {
  clients: [],
  loading_clients: false,
  error_clients: null,
};

const clientCSReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CLIENT_CS_REQUEST:
      return {
        ...state,
        loading_clients: true,
        error_clients: null,
      };
    case GET_CLIENT_CS_SUCCESS:
      return {
        ...state,
        loading_clients: false,
        clients: action.payload,
      };
    case GET_CLIENT_CS_FAILURE:
      return {
        ...state,
        loading_clients: false,
        error_clients: action.payload,
      };
    default:
      return state;
  }
};

export default clientCSReducer;
