import {
  endpoint_delete_product,
  endpoint_edit_product,
  endpoint_get_products,
  endpoint_get_products_details,
  endpoint_post_product,
} from "app/API/config";
import axiosInterceptor from "app/helpers/interceptor";
import { productTypes } from "app/redux/types";
import { format } from "date-fns";

const {
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,

  EDIT_PRODUCT_FAILURE,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,

  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,

  GET_PRODUCT_DETAILS_FAILURE,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,

  POST_PRODUCT_FAILURE,
  POST_PRODUCT_REQUEST,
  POST_PRODUCT_SUCCESS,
} = productTypes;

export const getProducts = () => async (dispatch) => {
  dispatch({ type: GET_PRODUCTS_REQUEST });
  const config = {
    url: endpoint_get_products,
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: GET_PRODUCTS_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_PRODUCTS_FAILURE, payload: error });
  }
};

export const getProductDetails = (id) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_DETAILS_REQUEST });
  const config = {
    url: endpoint_get_products_details.replace(":id", id),
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({
      type: GET_PRODUCT_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_PRODUCT_DETAILS_FAILURE, payload: error });
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_REQUEST });
  const config = {
    url: endpoint_delete_product.replace(":id", id),
    method: "DELETE",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: id, response: response });
  } catch (error) {
    dispatch({ type: DELETE_PRODUCT_FAILURE, payload: error });
  }
};

export const postProduct = (product, closeDialog) => async (dispatch) => {
  dispatch({ type: POST_PRODUCT_REQUEST });
  const formData = new FormData();

  Object.keys(product).forEach((key) => {
    if (
      product[key] !== null &&
      key !== "id" &&
      product[key] !== undefined &&
      product[key] !== ""
    ) {
      if (product[key] instanceof Date) {
        formData.append(key, format(product[key], "yyyy-MM-dd"));
      } else if (
        Array.isArray(product[key]) &&
        (key === "images" || key === "files")
      ) {
        product[key].forEach((item) => {
          formData.append(key, item);
        });
      } else if (
        Array.isArray(product[key]) &&
        (key === "colors" || key === "width")
      ) {
        product[key].forEach((item) => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(key, product[key]);
      }
    }
  });

  const config = {
    url: endpoint_post_product,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };

  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: POST_PRODUCT_SUCCESS, payload: response.data.data });
    closeDialog();
  } catch (error) {
    dispatch({ type: POST_PRODUCT_FAILURE, payload: error });
  }
};

export const editProduct =
  (product, selectedProduct, closeDialog) => async (dispatch) => {
    dispatch({ type: EDIT_PRODUCT_REQUEST });
    const formData = new FormData();

    Object.keys(product).forEach((key) => {
      if (
        product[key] !== null &&
        key !== "id" &&
        product[key] !== undefined &&
        product[key] !== ""
      ) {
        if (product[key] instanceof Date) {
          formData.append(key, format(product[key], "yyyy-MM-dd"));
        } else if (
          Array.isArray(product[key]) &&
          (key === "images" || key === "files")
        ) {
          product[key].forEach((item) => {
            formData.append(`${key}`, item);
          });
        } else if (
          Array.isArray(product[key]) &&
          (key === "colors" || key === "width")
        ) {
          product[key].forEach((item) => {
            formData.append(`${key}[]`, item);
          });
        } else {
          formData.append(key, product[key]);
        }
      }
    });

    const config = {
      url: endpoint_edit_product.replace(":id", selectedProduct?.id),
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    try {
      const response = await axiosInterceptor(config);
      dispatch({
        type: EDIT_PRODUCT_SUCCESS,
        payload: { ...response.data.data, id: selectedProduct.id },
      });
      dispatch(getProducts());
      closeDialog();
    } catch (error) {
      dispatch({ type: EDIT_PRODUCT_FAILURE, payload: error });
    }
  };
