import { authTypes } from "app/redux/types";
import axios from "axios";
import { useSnack } from "app/redux/actions/snacksActions";
import axiosInterceptor from "app/helpers/interceptor";
import { redirect } from "app/helpers/historyDom";
import { scopes } from "app/routes/routeList";
import {
  endpoint_get_user_profile,
  endpoint_login,
  endpoint_refresh_token,
} from "app/API/config";

const {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,

  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,

  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  //   LOGOUT_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
} = authTypes;

const getUserProfile = (isSignIn) => async (dispatch) => {
  dispatch({ type: GET_USER_PROFILE_REQUEST });
  const config = {
    url: endpoint_get_user_profile,
    method: "GET",
  };
  await axiosInterceptor(config)
    .then((response) => {
      dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: response.data.data });
      localStorage.setItem(
        "ses_user_profile",
        JSON.stringify(response.data.data)
      );
      if (isSignIn) {
        dispatch(
          useSnack(
            `Nous sommes ravis de vous revoir`,
            "success"
          )
        );
      }
    })
    .catch((error) => {
      // dispatch(
      //   useSnack(
      //     "Une erreur s'est produite lors de la recupération de vos informations , connectez vous à nouveau",
      //     "error"
      //   )
      // );
      localStorage.clear();
      dispatch({ type: GET_USER_PROFILE_FAILURE, payload: error });
      redirect(scopes.guest.signIn);
    });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT_REQUEST,
  });
  localStorage.removeItem("ses_token");
  localStorage.removeItem("ses_token_expiration_date");
  localStorage.removeItem("ses_user_profile");
  dispatch({
    type: LOGOUT_SUCCESS,
  });
  redirect(scopes.guest.home);
};

export const refreshToken = () => async (dispatch) => {
  dispatch({
    type: REFRESH_TOKEN_REQUEST,
  });
  const config = {
    url: endpoint_refresh_token,
    method: "POST",
  };

  await axiosInterceptor(config)
    .then((response) => {
      // const token_expiration_date = new Date(
      //   new Date().getTime() + 1 * 60 * 60 * 1000
      // );
      dispatch({
        type: REFRESH_TOKEN_SUCCESS,
        token: response.data.data.token,
        expiration_date: response.data.data.expireAt,
      });
      localStorage.setItem("ses_token", response.data.data.token);
      localStorage.setItem(
        "ses_token_expiration_date",
        response.data.data.expireAt
      );
      dispatch(getUserProfile(false));
    })
    .catch((error) => {
      // dispatch(
      //   useSnack(
      //     "Une erreur est survenue , s'il vous plait reconectez vous encore",
      //     "error"
      //   )
      // );
      dispatch({
        type: REFRESH_TOKEN_FAILURE,
        payload: error,
      });
      localStorage.clear();
    });
};

export const signIn = (user_data) => async (dispatch) => {
  dispatch({
    type: SIGNIN_REQUEST,
  });
  const payload = {
    ...user_data,
  };
  const config = {
    url: endpoint_login,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
    method: "POST",
  };
  await axios(config)
    .then((response) => {
      const token = response.data.data.token;
      dispatch({
        type: SIGNIN_SUCCESS,
        token: token,
        expiration_date: response.data.data.expireAt,
      });
      localStorage.setItem("ses_token", token);
      localStorage.setItem(
        "ses_token_expiration_date",
        response.data.data.expireAt
      );
      dispatch(getUserProfile(true));
      redirect(scopes.admin.home);
    })
    .catch((error) => {
      dispatch(useSnack("Veuillez vérifier vos identifiants!", "error"));
      dispatch({
        type: SIGNIN_FAILURE,
        payload: error,
      });
      localStorage.clear();
    });
};
