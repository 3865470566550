import { headerTypes } from "app/redux/types_client";

const { GET_HEADER_REQUEST, GET_HEADER_FAILURE, GET_HEADER_SUCCESS } =
  headerTypes;

const initState = {
  works: [],
  loading_works: false,
  error_works: null,
};

const headerReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_HEADER_REQUEST:
      return {
        ...state,
        loading_works: true,
        error_works: null,
      };
    case GET_HEADER_SUCCESS:
      return {
        ...state,
        loading_works: false,
        works: action.payload,
      };
    case GET_HEADER_FAILURE:
      return {
        ...state,
        loading_works: false,
        error_works: action.payload,
      };
    default:
      return state;
  }
};

export default headerReducer;
