import {
  ArrowBackRounded,
  DashboardRounded,
  DeleteRounded,
  // DownloadRounded,
  // DownloadRounded,
  FolderRounded,
  LoupeRounded,
  PointOfSaleRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { scopes } from "app/routes/routeList";
import CardDashPaper from "components/cards/CardDashPaper";
import BreadCrumbsNav from "components/navigation/BreadCrumbsNav";
import Title from "components/Text/Title";
import React, { Fragment, useEffect, useMemo, useState } from "react";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { GRID_DEFAULT_LOCALE_TEXT } from "utils/localtextGridTable";
import CustomNoRowsOverlay from "utils/CustomNoRowsOverlay";
import CustomLoadingOverlay from "utils/CustomLoadingOverlay";
import CustomToolbar from "utils/CustomToolBar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteQuotes,
  getDetailsQuotes,
  getQuotes,
} from "app/redux/actions/quotesActions";
// import { useDropzone } from "react-dropzone";
// import {
//   acceptStyle,
//   baseStyle,
//   focusedStyle,
//   rejectStyle,
// } from "theme/dropZoneTheme";
import CustomNoDataFound from "utils/CustomNoDataFound";
import { styled } from "@mui/material/styles";

import { getWilayas } from "app/redux/actions/otherActions";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const Root = styled("div")(({ theme }) => ({
  width: "100%",

  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
}));
const QuotesAdmin = () => {
  const quoteState = useSelector((state) => state.quoteState);
  const { quotes, loading_quotes } = quoteState;

  const otherState = useSelector((state) => state.otherState);
  const { wilayas, loading_wilayas } = otherState;

  const dispatch = useDispatch();

  const [selectedQuote, setselectedQuote] = useState({});

  const [openDialogDetails, setOpenDialogDetails] = useState(false);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    name: true,
    company: true,
    phone: true,
    email: true,
    wilaya: true,
    width: true,
    area: true,
    quantity: true,
    length: true,
    description: true,
    // product.isOther: true,
    // product.ref
    // product.description
    // product.name
    // product.width []
    // product.quantity
    // product.colors [] {code, hex , name}
    // product.images [] {url , name}
    // product.files [] {url , name }
    // product.order: false,
    // files [] {url , name }
    createdAt: true,
  });

  useEffect(() => {
    dispatch(getQuotes());
    dispatch(getWilayas());
  }, [dispatch]);

  const columns = [
    {
      headerName: "Actions 👇",
      field: "actions",
      type: "actions",
      hideable: false,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteRounded color="error" />}
          label="Supprimer"
          onClick={() => deleteRow(params)}
        />,
        <GridActionsCellItem
          icon={<LoupeRounded color="info" />}
          label="Voir les détails"
          onClick={() => handleOpenDialogDetails(params)}
        />,
      ],
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "company",
      headerName: "Entreprise",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "phone",
      headerName: "Téléphone",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
      // sortable: false,
    },
    {
      field: "wilaya",
      headerName: "Wilaya",
      flex: 1,
      minWidth: 150,
      type: "singleSelect",
      valueOptions: wilayas?.map((elmt) => elmt.name),
      // sortable: false,
      //selectable
    },
    {
      field: "width",
      headerName: "Epaisseur",
      flex: 1,
      minWidth: 150,
      // sortable: false,
      //selectable
    },
    {
      field: "length",
      headerName: "Longueur piéces",
      flex: 1,
      minWidth: 150,
      // sortable: false,
      //selectable
    },
    {
      field: "area",
      //m3
      headerName: "Total m²",
      flex: 1,
      minWidth: 150,
      // sortable: false,
      //selectable
    },
    {
      field: "quantity",
      headerName: "Nombre de piéces",
      flex: 1,
      minWidth: 150,
      // sortable: false,
      //selectable
    },
    {
      field: "description",
      headerName: "Description du projet",
      flex: 1,
      minWidth: 150,
      // sortable: false,
      //selectable
    },

    {
      field: "createdAt",
      headerName: "Date",
      type: "dateTime",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        // format to date time : dd MMM yyyy HH:mm
        return format(new Date(params.row.createdAt), "dd MMM yyyy HH:mm", {
          locale: fr,
        });
      },
    },
  ];

  const navigationBearcrumbs = [
    {
      label: "Tableau de bord",
      path: scopes.admin.home,

      icon: <DashboardRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: false,
    },
    {
      label: "Devis",
      path: scopes.admin.devis,

      icon: <PointOfSaleRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: true,
    },
  ];

  const deleteRow = (params) => {
    dispatch(deleteQuotes(params.row.id));
  };

  const handleOpenDialogDetails = (params) => {
    setselectedQuote(params.row);
    setOpenDialogDetails(true);
  };

  const handleCloseDialogDetails = () => {
    setselectedQuote({});
    setOpenDialogDetails(false);
  };

  // const downloadOneFile = async (url, fileName) => {
  //   if (url !== undefined && fileName !== undefined) {
  //     const xhr = new XMLHttpRequest();

  //     xhr.open("GET", url, true);
  //     xhr.responseType = "arraybuffer";

  //     xhr.onload = function (e) {
  //       if (this.status === 200) {
  //         const blob = new Blob([this.response], { type: this.response.type });
  //         const link = document.createElement("a");
  //         link.href = window.URL.createObjectURL(blob);
  //         link.download = fileName;
  //         link.click();
  //         link.remove();
  //         window.URL.revokeObjectURL(link.href);
  //       }
  //     };

  //     xhr.send();
  //   }
  // };

  // const downloadFileHelper = (files) => {
  //   if (files.length > 0) {
  //     files.forEach((file) => {
  //       downloadOneFile(file?.url, file?.name);
  //     });
  //   }
  // };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BreadCrumbsNav navigation={navigationBearcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <CardDashPaper>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Title
                title="Devis / Commandes"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              />
              <div style={{ display: "flex", height: 500, width: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={quotes}
                    columns={columns}
                    localeText={GRID_DEFAULT_LOCALE_TEXT}
                    loading={loading_quotes || loading_wilayas}
                    aria-label="devis_table"
                    aria-labelledby="devis_table_id"
                    disableSelectionOnClick
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "product.order", sort: "asc" }],
                      },
                    }}
                    componentsProps={{
                      toolbar: { csvOptions: { disableToolbarButton: true } },
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setColumnVisibilityModel(newModel)
                    }
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      LoadingOverlay: CustomLoadingOverlay,
                      Toolbar: CustomToolbar,
                    }}
                  />
                </div>
              </div>
            </Stack>
          </CardDashPaper>
        </Grid>
      </Grid>
      <DialogDetails
        open={openDialogDetails}
        title="Détails de la commande"
        handleClose={handleCloseDialogDetails}
        selectedRow={selectedQuote}
      />
    </Fragment>
  );
};

export default QuotesAdmin;

const DialogDetails = ({ open, handleClose, title, selectedRow }) => {
  const authState = useSelector((state) => state.authState);
  const { token } = authState;

  const quoteState = useSelector((state) => state.quoteState);

  const { quotes_details, loading_quotes_details } = quoteState;

  const dispatch = useDispatch();

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (selectedRow.id !== undefined) {
      dispatch(getDetailsQuotes(selectedRow?.id));
    }
  }, [dispatch, selectedRow]);

  const formFields = useMemo(() => {
    return [
      {
        name: "name",
        id: "name",
        label: "Nom",
        type: "string",
        initialValue: quotes_details?.name,
      },
      {
        name: "company",
        id: "company",
        label: "Entreprise",
        type: "string",
        initialValue: quotes_details?.company,
      },
      {
        name: "phone",
        id: "phone",
        label: "Numéro de téléphone",
        type: "string",
        initialValue: quotes_details?.phone,
      },
      {
        name: "email",
        id: "email",
        label: "Email",
        type: "string",
        initialValue: quotes_details?.email,
      },
      {
        name: "wilaya",
        id: "wilaya",
        label: "Wilaya",
        type: "string",
        initialValue: quotes_details?.wilaya,
      },
      {
        name: "area",
        id: "area",
        label: "Total m²",
        type: "string",
        initialValue: quotes_details?.area,
      },
      {
        name: "width",
        id: "width",
        label: "Epaisseur",
        type: "string",
        initialValue: quotes_details?.width,
      },
      {
        name: "length",
        id: "length",
        label: "Longueur piéces",
        type: "string",
        initialValue: quotes_details?.length,
      },
      {
        name: "quantity",
        id: "quantity",
        label: "Nombre de piéces",
        type: "string",
        initialValue: quotes_details?.quantity,
      },
      {
        name: "product.ref",
        id: "product.ref",
        label: "Référence du produit",
        type: "string",
        initialValue: quotes_details?.product?.ref,
      },
      {
        name: "product.name",
        id: "product.name",
        label: "Nom du produit",
        type: "string",
        initialValue: quotes_details?.product?.name,
      },
      {
        name: "createdAt",
        id: "createdAt",
        label: "Date de création",
        type: "string",
        initialValue: quotes_details?.createdAt,
      },
      {
        name: "description",
        id: "description",
        label: "Description du projet",
        type: "paragraph",
        initialValue: quotes_details?.description,
      },
      {
        name: "files",
        id: "files",
        label: "Documents relatifs au projet",
        type: "files",
        initialValue: quotes_details?.files,
      },
    ];
  }, [quotes_details]);

  const downloadOneFile = async (url, fileName) => {
    if (url !== undefined && fileName !== undefined) {
      const xhr = new XMLHttpRequest();

      xhr.open("GET", url, true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.responseType = "arraybuffer";

      xhr.onload = function (e) {
        if (this.status === 200) {
          const blob = new Blob([this.response], { type: this.response.type });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          link.remove();
          window.URL.revokeObjectURL(link.href);
        }
      };

      xhr.send();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <Divider
        sx={{
          my: 3,
        }}
      />
      <DialogContent>
        {loading_quotes_details ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              p: 4,
            }}
          >
            <CircularProgress />
          </Box>
        ) : quotes_details !== null ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
          >
            {formFields.map((field) =>
              field.type === "string" || field.type === "number" ? (
                <Grid item key={field.id} xs={12}>
                  <Typography variant="h6" gutterBottom align="left">
                    {field.label} : <strong>{field.initialValue}</strong>
                  </Typography>
                </Grid>
              ) : field.type === "paragraph" ? (
                <Grid item key={field.id} xs={12}>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Root>
                      <Divider>
                        <Chip label={field.label} />
                      </Divider>
                    </Root>
                    <Typography variant="caption" gutterBottom align="left">
                      <strong>{field.initialValue}</strong>
                    </Typography>
                  </Stack>
                </Grid>
              ) : (
                <Grid item key={field.id} xs={12}>
                  {field.initialValue !== null &&
                    field.initialValue !== undefined &&
                    field.initialValue.length > 0 && (
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}
                      >
                        <Root>
                          <Divider>
                            <Chip label="Documents" />
                          </Divider>
                        </Root>
                        {Array.isArray(field.initialValue) && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              overflowX: "scroll",
                              my: 3,
                            }}
                          >
                            {loading_quotes_details ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  py: 1,
                                  my: 3,
                                }}
                              >
                                <LinearProgress />
                              </Box>
                            ) : (
                              field.initialValue.map((file, index) => (
                                <Link
                                  component="button"
                                  key={index}
                                  onClick={() =>
                                    // downloadOneFile(file?.url, file?.name)
                                    downloadOneFile(file?.url, file?.name)
                                  }
                                >
                                  <Tooltip title={file?.name}>
                                    <Avatar
                                      // src={file.url}
                                      alt={file.name}
                                      variant="rounded"
                                      sx={{
                                        cursor: "pointer",
                                        width: 90,
                                        height: 90,
                                        mr: 1,
                                        mb: 1,
                                      }}
                                    >
                                      <FolderRounded />
                                    </Avatar>
                                  </Tooltip>
                                </Link>
                              ))
                            )}
                          </Box>
                        )}
                        {/* {isInProgress && <LinearProgress />} */}
                      </Stack>
                    )}
                </Grid>
              )
            )}
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="error"
                  startIcon={<ArrowBackRounded />}
                >
                  Revenir à la liste
                </Button>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <CustomNoDataFound />
        )}
      </DialogContent>
    </Dialog>
  );
};
