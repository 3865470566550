import { clientsType } from "app/redux/types";

const {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,

  GET_CLIENT_DETAILS_FAILURE,
  GET_CLIENT_DETAILS_REQUEST,
  GET_CLIENT_DETAILS_SUCCESS,

  POST_CLIENT_FAILURE,
  POST_CLIENT_REQUEST,
  POST_CLIENT_SUCCESS,

  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,

  EDIT_CLIENT_FAILURE,
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_SUCCESS,
} = clientsType;

const initState = {
  clients: [],
  client_detail: {},
  loading_clients: false,
  error_clients: null,
  loading_clients_details: false,
  error_clients_details: null,
};

const clientsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        loading_clients: false,
        error_clients: null,
      };

    case GET_CLIENT_DETAILS_REQUEST:
      return {
        ...state,
        loading_clients_details: true,
        error_clients_details: null,
      };
    case GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        client_detail: action.payload,
        loading_clients_details: false,
        error_clients_details: null,
      };
    case GET_CLIENT_DETAILS_FAILURE:
      return {
        ...state,
        loading_clients_details: false,
        error_clients_details: action.payload,
      };

    case POST_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload],
        loading_clients: false,
        error_clients: null,
      };

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(
          (client) => client.id.toString() !== action.payload.toString()
        ),
        loading_clients: false,
        error_clients: null,
      };

    case EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state?.clients.map((client) => {
          if (client.id.toString() === action.payload.id.toString()) {
            return action.payload;
          }
          return {
            ...client,
            order:
              client.order.toString() === action.payload.order.toString()
                ? client.order + 1
                : client.order,
          };
        }),
        loading_clients: false,
      };

    case EDIT_CLIENT_REQUEST:
    case POST_CLIENT_REQUEST:
    case DELETE_CLIENT_REQUEST:
    case GET_CLIENTS_REQUEST:
      return {
        ...state,
        loading_clients: true,
        error_clients: null,
      };
    case EDIT_CLIENT_FAILURE:
    case POST_CLIENT_FAILURE:
    case DELETE_CLIENT_FAILURE:
    case GET_CLIENTS_FAILURE:
      return {
        ...state,
        loading_clients: false,
        error_clients: action.payload,
      };
    default:
      return state;
  }
};

export default clientsReducer;
