import {
  endpoint_get_blog_details,
  endpoint_post_blog,
  endpoint_delete_blog,
  endpoint_put_blog,
  endpoint_get_blog,
} from "app/API/config";
import axiosInterceptor from "app/helpers/interceptor";
import { format } from "date-fns";
import { blogTypes } from "app/redux/types";

const {
  DELETE_BLOG_FAILURE,
  DELETE_BLOG_REQUEST,
  DELETE_BLOG_SUCCESS,
  EDIT_BLOG_FAILURE,
  EDIT_BLOG_REQUEST,
  EDIT_BLOG_SUCCESS,
  GET_BLOG_FAILURE,
  GET_BLOG_REQUEST,
  GET_BLOG_SUCCESS,
  GET_BLOG_DETAILS_FAILURE,
  GET_BLOG_DETAILS_REQUEST,
  GET_BLOG_DETAILS_SUCCESS,
  POST_BLOG_FAILURE,
  POST_BLOG_REQUEST,
  POST_BLOG_SUCCESS,
} = blogTypes;

export const getBlog = () => async (dispatch) => {
  dispatch({ type: GET_BLOG_REQUEST });
  const config = {
    url: endpoint_get_blog,
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: GET_BLOG_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_BLOG_FAILURE, payload: error });
  }
};

export const getBlogDetails = (id) => async (dispatch) => {
  dispatch({ type: GET_BLOG_DETAILS_REQUEST });
  const config = {
    url: endpoint_get_blog_details.replace(":id", id),
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({
      type: GET_BLOG_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_BLOG_DETAILS_FAILURE, payload: error });
  }
};

export const deleteBlog = (id) => async (dispatch) => {
  dispatch({ type: DELETE_BLOG_REQUEST });
  const config = {
    url: endpoint_delete_blog.replace(":id", id),
    method: "DELETE",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: DELETE_BLOG_SUCCESS, payload: id, response: response });
  } catch (error) {
    dispatch({ type: DELETE_BLOG_FAILURE, payload: error });
  }
};

export const postBlog = (blog, closeDialog) => async (dispatch) => {
  dispatch({ type: POST_BLOG_REQUEST });
  const formData = new FormData();

  Object.keys(blog).forEach((key) => {
    if (
      blog[key] !== null &&
      key !== "id" &&
      blog[key] !== undefined &&
      blog[key] !== ""
    ) {
      if (blog[key] instanceof Date) {
        formData.append(key, format(blog[key], "yyyy-MM-dd"));
      } else if (Array.isArray(blog[key])) {
        blog[key].forEach((item) => {
          formData.append(`${key}`, item);
        });
      } else {
        formData.append(key, blog[key]);
      }
    }
  });
  const config = {
    url: endpoint_post_blog,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: POST_BLOG_SUCCESS, payload: response.data.data });
    closeDialog();
  } catch (error) {
    dispatch({ type: POST_BLOG_FAILURE, payload: error });
  }
};

export const editBlog =
  (blog, selectedblog, closeDialog) => async (dispatch) => {
    dispatch({ type: EDIT_BLOG_REQUEST });
    const formData = new FormData();

    Object.keys(blog).forEach((key) => {
      if (
        blog[key] !== null &&
        key !== "id" &&
        blog[key] !== undefined &&
        blog[key] !== ""
      ) {
        if (blog[key] instanceof Date) {
          formData.append(key, format(blog[key], "yyyy-MM-dd"));
        } else if (Array.isArray(blog[key])) {
          blog[key].forEach((item) => {
            formData.append(`${key}`, item);
          });
        } else {
          formData.append(key, blog[key]);
        }
      }
    });
    const config = {
      url: endpoint_put_blog.replace(":id", selectedblog?.id),
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const response = await axiosInterceptor(config);
      dispatch({
        type: EDIT_BLOG_SUCCESS,
        payload: { ...response.data.data, id: selectedblog?.id },
      });
      dispatch(getBlog());
      closeDialog();
    } catch (error) {
      dispatch({ type: EDIT_BLOG_FAILURE, payload: error });
    }
  };
