import {
  endpoint_delete_member,
  endpoint_edit_member,
  endpoint_get_members,
  endpoint_get_members_details,
  endpoint_post_member,
} from "app/API/config";
import axiosInterceptor from "app/helpers/interceptor";
import { membersType } from "app/redux/types";
import { format } from "date-fns";
import { useSnack } from "app/redux/actions/snacksActions";
// import { createFile } from "app/helpers/createFile";

const {
  DELETE_MEMBER_FAILURE,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,

  EDIT_MEMBER_FAILURE,
  EDIT_MEMBER_REQUEST,
  EDIT_MEMBER_SUCCESS,

  POST_MEMBER_FAILURE,
  POST_MEMBER_REQUEST,
  POST_MEMBER_SUCCESS,

  GET_MEMBERS_FAILURE,
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_SUCCESS,

  GET_MEMBERS_DETAILS_REQUEST,
  GET_MEMBERS_DETAILS_FAILURE,
  GET_MEMBERS_DETAILS_SUCCESS,
} = membersType;

export const getMembers = () => async (dispatch) => {
  dispatch({ type: GET_MEMBERS_REQUEST });
  const config = {
    url: endpoint_get_members,
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: GET_MEMBERS_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_MEMBERS_FAILURE, payload: error });
  }
};

export const getMemberDetail = (id) => async (dispatch) => {
  dispatch({ type: GET_MEMBERS_DETAILS_REQUEST });
  const config = {
    url: endpoint_get_members_details.replace(":id", id),
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({
      type: GET_MEMBERS_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_MEMBERS_DETAILS_FAILURE, payload: error });
  }
};

export const postMember = (member, closeDialog) => async (dispatch) => {
  dispatch({ type: POST_MEMBER_REQUEST });
  const formData = new FormData();

  Object.keys(member).forEach((key) => {
    if (
      member[key] !== null &&
      member[key] !== undefined &&
      member[key] !== ""
    ) {
      if (member[key] instanceof Date) {
        formData.append(key, format(member[key], "yyyy-MM-dd"));
      } else {
        formData.append(key, member[key]);
      }
    }
  });

  console.log(formData);

  const config = {
    url: endpoint_post_member,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };

  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: POST_MEMBER_SUCCESS, payload: response.data.data });
    closeDialog();
  } catch (error) {
    dispatch({ type: POST_MEMBER_FAILURE, payload: error });
  }
};

export const deleteMember = (id) => async (dispatch) => {
  dispatch({ type: DELETE_MEMBER_REQUEST });
  const config = {
    url: endpoint_delete_member.replace(":id", id),
    method: "DELETE",
  };
  try {
    const response = await axiosInterceptor(config);
    // dispatch(useSnack(response.data.message, "success"));
    dispatch(useSnack(" Membre supprimé avec succès", "success"));
    dispatch({
      type: DELETE_MEMBER_SUCCESS,
      payload: response,
      id: id,
    });
  } catch (error) {
    dispatch({ type: DELETE_MEMBER_FAILURE, payload: error });
  }
};

export const updateMember = (member, closeDialog) => async (dispatch) => {
  dispatch({ type: EDIT_MEMBER_REQUEST });
  const formData = new FormData();

  Object.keys(member).forEach((key) => {
    if (
      member[key] !== null &&
      key !== "id" &&
      member[key] !== undefined &&
      member[key] !== ""
    ) {
      if (member[key] instanceof Date) {
        formData.append(key, format(member[key], "yyyy-MM-dd"));
      } else if (
        key === "image" &&
        member[key].url !== undefined &&
        member[key].url !== null &&
        member[key].url !== ""
      ) {
        // do nothing
        return;
      } else {
        formData.append(key, member[key]);
      }
    }
  });

  const config = {
    url: endpoint_edit_member.replace(":id", member.id),
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };

  try {
    const response = await axiosInterceptor(config);
    dispatch({
      type: EDIT_MEMBER_SUCCESS,
      payload: { ...response.data.data, id: member.id },
    });
    dispatch(getMembers());
    closeDialog();
  } catch (error) {
    dispatch({ type: EDIT_MEMBER_FAILURE, payload: error });
  }
};
