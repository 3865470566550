import { memberTypesClient } from "app/redux/types_client";

const {
  GET_MEMBER_CLIENT_FAILURE,
  GET_MEMBER_CLIENT_REQUEST,
  GET_MEMBER_CLIENT_SUCCESS,
} = memberTypesClient;

const initState = {
  members: [],
  loading_members: false,
  error_members: null,
};

const membersCSReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_MEMBER_CLIENT_REQUEST:
      return {
        ...state,
        loading_members: true,
        error_members: null,
      };
    case GET_MEMBER_CLIENT_SUCCESS:
      return {
        ...state,
        loading_members: false,
        members: action.payload,
      };

    case GET_MEMBER_CLIENT_FAILURE:
      return {
        ...state,
        loading_members: false,
        error_members: action.payload,
      };
    default:
      return state;
  }
};

export default membersCSReducer;
