import { refreshToken } from "app/redux/actions/authActions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const RefreshToken = () => {
  const authState = useSelector((state) => state.authState);
  const { token_expiration_date } = authState;
  const dispatch = useDispatch();
  const [days, hours, minutes, seconds] = useCountdown(token_expiration_date);

  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      dispatch(refreshToken());
    }
  }, [days, hours, minutes, seconds, dispatch]);

  /* <div>
    days : {days} , hours : {hours} , minutes : {minutes} , seconds :{" "}
    {seconds}
  </div> */
  return null;
};

export default RefreshToken;
