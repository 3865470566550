import {
  AddRounded,
  // CancelOutlined,
  // CancelRounded,
  CloseRounded,
  DashboardRounded,
  DeleteRounded,
  FolderRounded,
  Inventory,
  LoupeRounded,
  SaveRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import { width } from "@mui/system";
import { deleteMedia } from "app/redux/actions/medias";
import { getColorsRals } from "app/redux/actions/otherActions";
import {
  deleteProduct,
  editProduct,
  getProductDetails,
  getProducts,
  postProduct,
} from "app/redux/actions/productActions";
import { scopes } from "app/routes/routeList";
import CardDashPaper from "components/cards/CardDashPaper";
import BreadCrumbsNav from "components/navigation/BreadCrumbsNav";
import Title from "components/Text/Title";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "theme/dropZoneTheme";
import CustomNoDataFound from "utils/CustomNoDataFound";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import { fileTypesFiles, fileTypesImages } from "utils/authFiles";

const Root = styled("div")(({ theme }) => ({
  width: "100%",

  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const ProductsAdmin = () => {
  const productState = useSelector((state) => state.productState);
  const { products, loading_products } = productState;

  const [openDialogPost, setopenDialogPost] = useState(false);
  const [openDialogEdit, setopenDialogEdit] = useState(false);
  const [selectedProduct, setselectedProduct] = useState({});

  const [page, setpage] = useState(1);
  const cardPerPage = 8;

  const handleChangePage = (event, newPage) => {
    setpage(newPage);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const navigationBearcrumbs = [
    {
      label: "Tableau de bord",
      path: scopes.admin.home,

      icon: <DashboardRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: false,
    },
    {
      label: "Produits",
      path: scopes.admin.products,

      icon: <Inventory sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: true,
    },
  ];

  const deleteRow = (params) => {
    dispatch(deleteProduct(params.id));
  };

  const handleCloseDialogDetails = () => {
    setselectedProduct({});
    setopenDialogEdit(false);
  };

  const handleOpenDialogDetails = (params) => {
    setselectedProduct(params);
    setopenDialogEdit(true);
  };

  const handleOpenDialogPost = () => {
    setopenDialogPost(true);
  };
  const handleCloseDialogPost = () => {
    setopenDialogPost(false);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BreadCrumbsNav navigation={navigationBearcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "flex-end",
              },
              width: "100%",
            }}
          >
            <Button
              endIcon={<AddRounded />}
              variant="text"
              color="info"
              onClick={handleOpenDialogPost}
            >
              Ajouter un nouveau produit
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <CardDashPaper>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Title
                title="Produits"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              />
              {loading_products ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    py: 10,
                    px: 2,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : products.length > 0 ? (
                <Box
                  sx={{
                    px: 2,
                    py: 2,
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={4}
                  >
                    {products
                      ?.slice((page - 1) * cardPerPage, page * cardPerPage)
                      ?.sort((a, b) => {
                        return a.order - b.order;
                      })
                      ?.map((product, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                          <Box
                            component={Card}
                            sx={{
                              width: "100%",
                              height: "100%",

                              borderRadius: 2,
                            }}
                          >
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              height={200}
                              image={product?.images[0]?.url}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                                justifyContent: "space-between",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="subtitle2"
                                  component="div"
                                >
                                  {product.name_fr}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="caption"
                                  component="div"
                                  sx={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {product.ref}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100%",
                                  }}
                                >
                                  {product.description}
                                </Typography>
                              </CardContent>
                              <CardActions
                                disableSpacing
                                sx={{
                                  // backgroundColor: "red",
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "stretch",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Tooltip title="Supprimer">
                                    <IconButton
                                      size="small"
                                      onClick={() => deleteRow(product)}
                                      color="error"
                                    >
                                      <DeleteRounded />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Voir les détails / Modifier">
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleOpenDialogDetails(product)
                                      }
                                      color="info"
                                    >
                                      <LoupeRounded />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </CardActions>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 2,
                          width: "100%",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(products?.length / cardPerPage)}
                          page={page}
                          onChange={handleChangePage}
                          variant="outlined"
                          color="primary"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    py: 10,
                  }}
                >
                  <CustomNoDataFound />
                </Box>
              )}
            </Stack>
          </CardDashPaper>
        </Grid>
      </Grid>
      <DialogPost
        open={openDialogPost}
        title="Ajouter un nouveau produit"
        handleClose={handleCloseDialogPost}
      />
      <DialogUpdate
        open={openDialogEdit}
        title="Modifier un produit"
        handleClose={handleCloseDialogDetails}
        selectedRow={selectedProduct}
      />
    </Fragment>
  );
};

export default ProductsAdmin;

const DialogUpdate = ({ open, handleClose, title, selectedRow }) => {
  const productState = useSelector((state) => state.productState);
  const {
    loading_products,
    loading_products_details,
    products_details,
    loading_content,
  } = productState;
  const otherState = useSelector((state) => state.otherState);

  const { colorsRals, loading_colorsRals } = otherState;

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  let dropZoneFiles = useDropzone({
    accept: fileTypesFiles,
    // maxFiles: 1,
    multiple: true,
    onDropAccepted: (files) => {
      formik.setFieldValue("files", files);
    },
  });

  let dropZoneImages = useDropzone({
    accept: fileTypesImages,
    // maxFiles: 1,
    multiple: true,
    onDropAccepted: (files) => {
      formik.setFieldValue("images", files);
    },
  });

  useEffect(() => {
    if (selectedRow.id !== undefined) {
      dispatch(getProductDetails(selectedRow?.id));
    }
  }, [dispatch, selectedRow]);

  const style_images = useMemo(
    () => ({
      ...baseStyle,
      ...(dropZoneImages.isFocused ? focusedStyle : {}),
      ...(dropZoneImages.isDragAccept ? acceptStyle : {}),
      ...(dropZoneImages.isDragReject ? rejectStyle : {}),
    }),
    [
      dropZoneImages.isFocused,
      dropZoneImages.isDragAccept,
      dropZoneImages.isDragReject,
    ]
  );

  const style_files = useMemo(
    () => ({
      ...baseStyle,
      ...(dropZoneFiles.isFocused ? focusedStyle : {}),
      ...(dropZoneFiles.isDragAccept ? acceptStyle : {}),
      ...(dropZoneFiles.isDragReject ? rejectStyle : {}),
    }),
    [
      dropZoneFiles.isFocused,
      dropZoneFiles.isDragAccept,
      dropZoneFiles.isDragReject,
    ]
  );

  const validationSchema = yup.object({
    ref: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(150, "Vous ne pouvez pas saisir plus de 150 caractères"),
    name_fr: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(150, "Vous ne pouvez pas saisir plus de 150 caractères"),
    name_en: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(150, "Vous ne pouvez pas saisir plus de 150 caractères"),
    name_ar: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(150, "Vous ne pouvez pas saisir plus de 150 caractères"),
    colors: yup
      .array()
      .of(yup.object({}))
      .min(1, "Ce champ est obligatoire")
      .required("Ce champ est obligatoire"),
    width: yup
      .array()
      .of(yup.string())
      .min(1, "Ce champ est obligatoire")
      .required("Ce champ est obligatoire"),
    // quantity: yup
    //   .number("Ce champ est obligatoire")
    //   .required("Ce champ est obligatoire"),
    description_fr: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(1000, "Vous ne pouvez pas saisir plus de 1000 caractères"),
    description_en: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(1000, "Vous ne pouvez pas saisir plus de 1000 caractères"),

    description_ar: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(1000, "Vous ne pouvez pas saisir plus de 1000 caractères"),
    images: yup
      .array()
      .of(yup.mixed())
      .min(1, "Vous devez ajouter au moins une image")
      .required("Vous devez ajouter au moins une image"),
    order: yup.number().required("Ce champ est obligatoire"),
    // files: yup
    //   .array()
    //   .of(yup.mixed())
    //   .min(1, "Vous devez aujouter au moins un fichier")
    //   .required("Vous devez ajouter au moins un fichier"),
  });

  let formFields = useMemo(() => {
    return [
      {
        name: "ref",
        id: "ref",
        label: "Référence",
        type: "string",
        initialValue: products_details?.ref,
      },
      {
        name: "name_fr",
        id: "name_fr",
        label: "Nom (Français)",
        type: "string",
        initialValue: products_details?.name_fr,
      },
      {
        name: "name_en",
        id: "name_en",
        label: "Nom (Anglais)",
        type: "string",
        initialValue: products_details?.name_en,
      },
      {
        name: "name_ar",
        id: "name_ar",
        label: "Nom (Arabe)",
        type: "string",
        initialValue: products_details?.name_ar,
      },
      {
        name: "colors",
        id: "colors",
        label: "Couleurs",
        type: "arrayStringColors",
        initialValue: products_details?.colors,
        options: colorsRals,
      },
      {
        name: "width",
        id: "width",
        label: "Largeur mm",
        type: "arrayStringWidth",
        initialValue: products_details?.width,
        options: [],
      },
      {
        name: "quantity",
        id: "quantity",
        label: "Quantité",
        type: "number",
        initialValue: products_details?.quantity,
      },
      {
        name: "description_fr",
        id: "description_fr",
        label: "Description (Français)",
        type: "textarea",
        initialValue: products_details?.description_fr,
      },
      {
        name: "description_en",
        id: "description_en",
        label: "Description (Anglais)",
        type: "textarea",
        initialValue: products_details?.description_en,
      },
      {
        name: "description_ar",
        id: "description_ar",
        label: "Description (Arabe)",
        type: "textarea",
        initialValue: products_details?.description_ar,
      },
      {
        name: "order",
        id: "order",
        label: "Ordre",
        type: "number",
        initialValue: products_details?.order,
      },
      {
        name: "images",
        id: "images",
        label: "Images",
        instructions:
          "Glisser et déposer des images ici, ou cliquer pour en sélectionner une ou plusieurs images",
        type: "arrayImages",
        initialValue: products_details?.images,
      },
      {
        name: "files",
        id: "files",
        label: "Fichiers",
        type: "arrayFiles",
        initialValue: products_details?.files,
        instructions:
          "Glisser et déposer des fichiers ici, ou cliquer pour en sélectionner un ou plusieurs fichiers",
      },
    ];
  }, [colorsRals, products_details]);

  let initialValues = useMemo(() => {
    return formFields.reduce((acc, field) => {
      acc[field.name] = field.initialValue;
      return acc;
    }, {});
  }, [formFields]);

  let formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        ...values,
        colors: values?.colors?.map((color) => color.id) || [],
        images: values?.images?.filter((image) => image instanceof File) || [],
        files: values?.files?.filter((file) => file instanceof File) || [],
      };

      dispatch(editProduct(payload, selectedRow, closeAndResetForm));
    },
  });

  const closeAndResetForm = () => {
    formik.resetForm();
    removeAllFiles();
    handleClose();
  };

  const removeAllFiles = () => {
    dropZoneFiles.acceptedFiles.length = 0;
    dropZoneFiles.acceptedFiles.splice(0, dropZoneFiles.acceptedFiles.length);
    dropZoneFiles.inputRef.current.value = "";

    dropZoneImages.acceptedFiles.length = 0;
    dropZoneImages.acceptedFiles.splice(0, dropZoneImages.acceptedFiles.length);
    dropZoneImages.inputRef.current.value = "";
  };

  const confirmDialog = (file, field) => {
    var confirmation = window.confirm("Voulez-vous supprimer cette image ?");
    if (confirmation === true) {
      dispatch(deleteMedia(file.id));
      formFields = formFields.map((elmt) => {
        if (elmt.name === field.name) {
          elmt.initialValue = elmt.initialValue.filter(
            (f) => f.id.toString() !== file.id.toString()
          );
        }
        return elmt;
      });
      formik.setFieldValue(
        field.name,
        field.initialValue.filter(
          (item) => item.id.toString() !== file.id.toString()
        )
      );
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={closeAndResetForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {loading_products_details ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              p: 4,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            component="form"
            // noValidate
            sx={{ mt: 1 }}
            onSubmit={formik.handleSubmit}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              {formFields.map((field) => (
                <Grid item key={field.id} xs={12}>
                  {field.type === "arrayImages" ? (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Root>
                        <Divider>
                          <Chip label="Images" />
                        </Divider>
                      </Root>
                      {/* <Typography variant="subtitle1" gutterBottom align="left">
                        Images
                      </Typography> */}
                      {Array.isArray(field.initialValue) && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            overflowX: "scroll",
                            my: 3,
                          }}
                        >
                          {loading_content ? (
                            <Box
                              sx={{
                                width: "100%",
                                py: 1,
                                my: 3,
                              }}
                            >
                              <LinearProgress />
                            </Box>
                          ) : (
                            field.initialValue.map((image, index) =>
                              field.initialValue.length > 1 ? (
                                <Badge
                                  key={index}
                                  color="error"
                                  overlap="circular"
                                  badgeContent={
                                    <p
                                      onClick={() => {
                                        confirmDialog(image, field);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      X
                                    </p>
                                  }
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <Avatar
                                    src={image.url}
                                    alt={image.name}
                                    variant="rounded"
                                    sx={{
                                      width: 90,
                                      height: 90,
                                      mr: 1,
                                      mb: 1,
                                    }}
                                  />
                                </Badge>
                              ) : (
                                <Avatar
                                  key={index}
                                  src={image.url}
                                  alt={image.name}
                                  variant="rounded"
                                  sx={{
                                    width: 90,
                                    height: 90,
                                    mr: 1,
                                    mb: 1,
                                  }}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                      <section className="container">
                        <div
                          {...dropZoneImages.getRootProps({
                            style: style_images,
                          })}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Box
                            component="div"
                            {...dropZoneImages.getRootProps({
                              className: "dropzone",
                            })}
                            sx={{
                              p: "30px",
                            }}
                          >
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="stretch"
                              spacing={2}
                            >
                              {dropZoneImages.acceptedFiles.length > 0 && (
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    overflowX: "scroll",
                                  }}
                                >
                                  {dropZoneImages.acceptedFiles?.map(
                                    (file, index) => {
                                      return (
                                        <Avatar
                                          key={index}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                          variant="rounded"
                                          sx={{
                                            width: 90,
                                            height: 90,
                                            mr: 1,
                                            mb: 1,
                                          }}
                                        />
                                      );
                                    }
                                  )}
                                </Box>
                              )}
                              <div
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input {...dropZoneImages.getInputProps()} />
                                <p>{field.instructions}</p>
                                {/* <em>
                            {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                          </em> */}
                              </div>
                            </Stack>
                            <Box
                              sx={{
                                my: 2,
                              }}
                            />
                            <aside>
                              {dropZoneImages.fileRejections.length > 0 && (
                                <>
                                  <h4>image refusé</h4>
                                  <ul>
                                    {dropZoneImages.fileRejections.map(
                                      ({ file, errors }) => (
                                        <li key={file.path}>
                                          {file.path} - {file.size} bytes
                                          <ul>
                                            {errors.map((e) => (
                                              <li key={e.code}>{e.message}</li>
                                            ))}
                                          </ul>
                                        </li>
                                      )
                                    )}
                                  </ul>{" "}
                                </>
                              )}
                            </aside>
                          </Box>
                        </div>
                        {formik.touched[field.name] &&
                          Boolean(formik.errors[field.name]) && (
                            <Alert
                              severity="error"
                              variant="outlined"
                              sx={{
                                mt: 2,
                              }}
                            >
                              {formik.touched[field.name] &&
                                formik.errors[field.name]}
                            </Alert>
                          )}
                      </section>
                    </Box>
                  ) : field.type === "arrayFiles" ? (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Root>
                        <Divider>
                          <Chip label="Documents" />
                        </Divider>
                      </Root>
                      {/* <Typography variant="subtitle1" gutterBottom align="left">
                        Documents
                      </Typography> */}
                      {Array.isArray(field.initialValue) && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            overflowX: "scroll",
                            my: 3,
                          }}
                        >
                          {loading_content ? (
                            <Box
                              sx={{
                                width: "100%",
                                py: 1,
                                my: 3,
                              }}
                            >
                              <LinearProgress />
                            </Box>
                          ) : (
                            field.initialValue.map((file, index) =>
                              field.initialValue.length > 1 ? (
                                <Tooltip key={index} title={file.name}>
                                  <Badge
                                    color="error"
                                    overlap="circular"
                                    badgeContent={
                                      <p
                                        onClick={() => {
                                          confirmDialog(file, field);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        X
                                      </p>
                                    }
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <Avatar
                                      // src={file.url}
                                      // alt={file.name}
                                      variant="rounded"
                                      sx={{
                                        width: 90,
                                        height: 90,
                                        mr: 1,
                                        mb: 1,
                                      }}
                                    >
                                      <FolderRounded />
                                    </Avatar>
                                  </Badge>
                                </Tooltip>
                              ) : (
                                <Tooltip key={index} title={file.name}>
                                  <Avatar
                                    // src={file.url}
                                    // alt={file.name}
                                    variant="rounded"
                                    sx={{
                                      width: 90,
                                      height: 90,
                                      mr: 1,
                                      mb: 1,
                                    }}
                                  >
                                    <FolderRounded />
                                  </Avatar>
                                </Tooltip>
                              )
                            )
                          )}
                        </Box>
                      )}
                      <section className="container">
                        <div
                          {...dropZoneFiles.getRootProps({
                            style: style_files,
                          })}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Box
                            component="div"
                            {...dropZoneFiles.getRootProps({
                              className: "dropzone",
                            })}
                            sx={{
                              p: "30px",
                            }}
                          >
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="stretch"
                              spacing={2}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input {...dropZoneFiles.getInputProps()} />
                                <p>{field.instructions}</p>
                                {/* <em>
                            {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                          </em> */}
                              </div>
                            </Stack>
                            <Box
                              sx={{
                                my: 2,
                              }}
                            />
                            <aside>
                              {dropZoneFiles.acceptedFiles.length > 0 && (
                                <>
                                  <h4>fichiers accéptés </h4>
                                  <ul>
                                    {dropZoneFiles.acceptedFiles?.map(
                                      (file, index) => {
                                        return (
                                          <li key={file.path}>
                                            {file.path} - {file.size} bytes
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </>
                              )}
                              {dropZoneFiles.fileRejections.length > 0 && (
                                <>
                                  <h4>fichiers refusés</h4>
                                  <ul>
                                    {dropZoneFiles.fileRejections.map(
                                      ({ file, errors }) => (
                                        <li key={file.path}>
                                          {file.path} - {file.size} bytes
                                          <ul>
                                            {errors.map((e) => (
                                              <li key={e.code}>{e.message}</li>
                                            ))}
                                          </ul>
                                        </li>
                                      )
                                    )}
                                  </ul>{" "}
                                </>
                              )}
                            </aside>
                          </Box>
                        </div>
                        {formik.touched[field.name] &&
                          Boolean(formik.errors[field.name]) && (
                            <Alert
                              severity="error"
                              variant="outlined"
                              sx={{
                                mt: 2,
                              }}
                            >
                              {formik.touched[field.name] &&
                                formik.errors[field.name]}
                            </Alert>
                          )}
                      </section>
                    </Box>
                  ) : field.type === "string" ? (
                    <TextField
                      fullWidth
                      id={field.id}
                      name={field.name}
                      label={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.name] &&
                        Boolean(formik.errors[field.name])
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  ) : field.type === "textarea" ? (
                    <TextField
                      multiline
                      rows={4}
                      fullWidth
                      id={field.id}
                      name={field.name}
                      label={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.name] &&
                        Boolean(formik.errors[field.name])
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  ) : field.type === "arrayStringColors" ? (
                    <Autocomplete
                      multiple
                      // freeSolo
                      id={field.id}
                      name={field.name}
                      value={formik.values[field.name] || []}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(field.name, newValue);
                      }}
                      options={field.options}
                      getOptionLabel={(option) => option.code}
                      isOptionEqualToValue={(option, value) => {
                        return option.code === value.code;
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              borderRadius: "50%",
                              backgroundColor: option?.hex,
                              mx: 2,
                            }}
                          />
                          {option?.code} _ {option?.name}
                        </Box>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={`${option?.code} _ ${option?.name}`}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={field.label}
                          fullWidth
                          label={field.label}
                          // value={formik.values[field.name]}
                          // onChange={formik.handleChange}

                          error={
                            formik.touched[field.name] &&
                            Boolean(formik.errors[field.name])
                          }
                          helperText={
                            formik.touched[field.name] &&
                            formik.errors[field.name]
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading_colorsRals ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  ) : field.type === "arrayStringWidth" ? (
                    <Autocomplete
                      multiple
                      freeSolo
                      id={field.id}
                      name={field.name}
                      // options={top100Films.map((option) => option.title)}
                      // defaultValue={[top100Films[13].title]}
                      // value={field.options.find(
                      //   (item) => item.code === formik.values[field.name]
                      // )}
                      options={field.options}
                      // getOptionLabel={(option) => option.code}
                      value={formik.values[field.name] || []}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(field.name, newValue);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={field.label}
                          fullWidth
                          label={field.label}
                          // value={formik.values[field.name]}
                          // onChange={formik.handleChange}

                          error={
                            formik.touched[field.name] &&
                            Boolean(formik.errors[field.name])
                          }
                          helperText={
                            formik.touched[field.name] &&
                            formik.errors[field.name]
                          }
                        />
                      )}
                    />
                  ) : field.type === "number" ? (
                    <TextField
                      type="number"
                      fullWidth
                      id={field.id}
                      name={field.name}
                      label={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.name] &&
                        Boolean(formik.errors[field.name])
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  ) : null}
                </Grid>
              ))}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    onClick={closeAndResetForm}
                    variant="outlined"
                    color="error"
                    endIcon={<CloseRounded />}
                  >
                    Annuler
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SaveRounded />}
                    loading={loading_products}
                  >
                    Enregistrer
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

const DialogPost = ({ open, handleClose, title }) => {
  const productState = useSelector((state) => state.productState);
  const { loading_products } = productState;

  const otherState = useSelector((state) => state.otherState);
  /* [
  {
    "code": "string",
    "hex": "string",
    "name": "string"
  }
] */
  const { colorsRals, loading_colorsRals } = otherState;

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getColorsRals());
  }, [dispatch]);

  /* let {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    // inputRef,
    // rootRef,
  } = */

  let dropZoneFiles = useDropzone({
    accept: fileTypesFiles,
    // maxFiles: 1,
    multiple: true,
    onDropAccepted: (files) => {
      formik.setFieldValue("files", files);
    },
  });

  let dropZoneImages = useDropzone({
    accept: fileTypesImages,
    // maxFiles: 1,
    multiple: true,
    onDropAccepted: (files) => {
      formik.setFieldValue("images", files);
    },
  });

  const style_images = useMemo(
    () => ({
      ...baseStyle,
      ...(dropZoneImages.isFocused ? focusedStyle : {}),
      ...(dropZoneImages.isDragAccept ? acceptStyle : {}),
      ...(dropZoneImages.isDragReject ? rejectStyle : {}),
    }),
    [
      dropZoneImages.isFocused,
      dropZoneImages.isDragAccept,
      dropZoneImages.isDragReject,
    ]
  );

  const style_files = useMemo(
    () => ({
      ...baseStyle,
      ...(dropZoneFiles.isFocused ? focusedStyle : {}),
      ...(dropZoneFiles.isDragAccept ? acceptStyle : {}),
      ...(dropZoneFiles.isDragReject ? rejectStyle : {}),
    }),
    [
      dropZoneFiles.isFocused,
      dropZoneFiles.isDragAccept,
      dropZoneFiles.isDragReject,
    ]
  );

  const validationSchema = yup.object({
    ref: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(150, "Vous ne pouvez pas saisir plus de 150 caractères"),
    name_fr: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(150, "Vous ne pouvez pas saisir plus de 150 caractères"),
    name_en: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(150, "Vous ne pouvez pas saisir plus de 150 caractères"),
    name_ar: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(150, "Vous ne pouvez pas saisir plus de 150 caractères"),

    colors: yup
      .array()
      .of(yup.string())
      .min(1, "Ce champ est obligatoire")
      .required("Ce champ est obligatoire"),
    width: yup
      .array()
      .of(yup.string())
      .min(1, "Ce champ est obligatoire")
      .required("Ce champ est obligatoire"),
    // quantity: yup
    //   .number("Ce champ est obligatoire")
    //   .required("Ce champ est obligatoire"),
    description_fr: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(1000, "Vous ne pouvez pas saisir plus de 1000 caractères"),
    description_en: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(1000, "Vous ne pouvez pas saisir plus de 1000 caractères"),

    description_ar: yup
      .string()
      .required("Ce champ est obligatoire")
      .max(1000, "Vous ne pouvez pas saisir plus de 1000 caractères"),
    images: yup
      .array()
      .of(yup.mixed())
      .min(1, "Vous devez ajouter au moins une image")
      .required("Vous devez ajouter au moins une image"),
    // files: yup
    //   .array()
    //   .of(yup.mixed())
    //   .min(1, "Vous devez aujouter au moins un fichier")
    //   .required("Vous devez ajouter au moins un fichier"),
  });

  const formFields = useMemo(() => {
    return [
      {
        name: "ref",
        id: "ref",
        label: "Référence",
        type: "string",
        initialValue: "",
      },
      {
        name: "name_fr",
        id: "name_fr",
        label: "Nom (Français)",
        type: "string",
        initialValue: "",
      },
      {
        name: "name_en",
        id: "name_en",
        label: "Nom (Anglais)",
        type: "string",
        initialValue: "",
      },
      {
        name: "name_ar",
        id: "name_ar",
        label: "Nom (Arabe)",
        type: "string",
        initialValue: "",
      },
      {
        name: "colors",
        id: "colors",
        label: "Couleurs",
        type: "arrayStringColors",
        initialValue: [],
        options: colorsRals,
      },
      {
        name: "width",
        id: "width",
        label: "Largeur mm",
        type: "arrayStringWidth",
        initialValue: [],
        options: [],
      },
      {
        name: "quantity",
        id: "quantity",
        label: "Quantité",
        type: "number",
        initialValue: "",
      },
      {
        name: "description_fr",
        id: "description_fr",
        label: "Description (Français)",
        type: "textarea",
        initialValue: "",
      },
      {
        name: "description_en",
        id: "description_en",
        label: "Description (Anglais)",
        type: "textarea",
        initialValue: "",
      },
      {
        name: "description_ar",
        id: "description_ar",
        label: "Description (Arabe)",
        type: "textarea",
        initialValue: "",
      },
      {
        name: "images",
        id: "images",
        label: "Images",
        instructions:
          "Glisser et déposer des images ici, ou cliquer pour en sélectionner une ou plusieurs images",
        type: "arrayImages",
        initialValue: [],
      },
      {
        name: "files",
        id: "files",
        label: "Fichiers",
        type: "arrayFiles",
        initialValue: [],
        instructions:
          "Glisser et déposer des fichiers ici, ou cliquer pour en sélectionner un ou plusieurs fichiers",
      },
    ];
  }, [colorsRals]);

  const initialValues = useMemo(() => {
    return formFields.reduce((acc, field) => {
      acc[field.name] = field.initialValue;
      return acc;
    }, {});
  }, [formFields]);

  let formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(postProduct(values, closeAndResetForm));
    },
  });

  const closeAndResetForm = () => {
    formik.resetForm();
    removeAllFiles();
    handleClose();
  };

  const removeAllFiles = () => {
    dropZoneFiles.acceptedFiles.length = 0;
    dropZoneFiles.acceptedFiles.splice(0, dropZoneFiles.acceptedFiles.length);
    dropZoneFiles.inputRef.current.value = "";

    dropZoneImages.acceptedFiles.length = 0;
    dropZoneImages.acceptedFiles.splice(0, dropZoneImages.acceptedFiles.length);
    dropZoneImages.inputRef.current.value = "";
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={closeAndResetForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          // noValidate
          sx={{ mt: 1 }}
          onSubmit={formik.handleSubmit}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
          >
            {formFields.map((field) => (
              <Grid item key={field.id} xs={12}>
                {field.type === "arrayImages" ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Root>
                      <Divider>
                        <Chip label="Images" />
                      </Divider>
                    </Root>
                    <Box
                      sx={{
                        my: 3,
                      }}
                    />
                    <section className="container">
                      <div
                        {...dropZoneImages.getRootProps({
                          style: style_images,
                        })}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box
                          component="div"
                          {...dropZoneImages.getRootProps({
                            className: "dropzone",
                          })}
                          sx={{
                            p: "30px",
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={2}
                          >
                            {dropZoneImages.acceptedFiles.length > 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  overflowX: "scroll",
                                }}
                              >
                                {dropZoneImages.acceptedFiles?.map(
                                  (file, index) => {
                                    return (
                                      <Avatar
                                        key={index}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        variant="rounded"
                                        sx={{ width: 80, height: 80, p: 2 }}
                                      />
                                    );
                                  }
                                )}
                              </Box>
                            )}
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <input {...dropZoneImages.getInputProps()} />
                              <p>{field.instructions}</p>
                              {/* <em>
                            {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                          </em> */}
                            </div>
                          </Stack>
                          <Box
                            sx={{
                              my: 2,
                            }}
                          />
                          <aside>
                            {dropZoneImages.fileRejections.length > 0 && (
                              <>
                                <h4>image refusé</h4>
                                <ul>
                                  {dropZoneImages.fileRejections.map(
                                    ({ file, errors }) => (
                                      <li key={file.path}>
                                        {file.path} - {file.size} bytes
                                        <ul>
                                          {errors.map((e) => (
                                            <li key={e.code}>{e.message}</li>
                                          ))}
                                        </ul>
                                      </li>
                                    )
                                  )}
                                </ul>{" "}
                              </>
                            )}
                          </aside>
                        </Box>
                      </div>
                      {formik.touched[field.name] &&
                        Boolean(formik.errors[field.name]) && (
                          <Alert
                            severity="error"
                            variant="outlined"
                            sx={{
                              mt: 2,
                            }}
                          >
                            {formik.touched[field.name] &&
                              formik.errors[field.name]}
                          </Alert>
                        )}
                    </section>
                  </Box>
                ) : field.type === "arrayFiles" ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Root>
                      <Divider>
                        <Chip label="Documents" />
                      </Divider>
                    </Root>
                    <Box
                      sx={{
                        my: 3,
                      }}
                    />
                    <section className="container">
                      <div
                        {...dropZoneFiles.getRootProps({ style: style_files })}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box
                          component="div"
                          {...dropZoneFiles.getRootProps({
                            className: "dropzone",
                          })}
                          sx={{
                            p: "30px",
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={2}
                          >
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <input {...dropZoneFiles.getInputProps()} />
                              <p>{field.instructions}</p>
                              {/* <em>
                            {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                          </em> */}
                            </div>
                          </Stack>
                          <Box
                            sx={{
                              my: 2,
                            }}
                          />
                          <aside>
                            {dropZoneFiles.acceptedFiles.length > 0 && (
                              <>
                                <h4>fichiers accéptés </h4>
                                <ul>
                                  {dropZoneFiles.acceptedFiles?.map(
                                    (file, index) => {
                                      return (
                                        <li key={file.path}>
                                          {file.path} - {file.size} bytes
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </>
                            )}
                            {dropZoneFiles.fileRejections.length > 0 && (
                              <>
                                <h4>fichiers refusés</h4>
                                <ul>
                                  {dropZoneFiles.fileRejections.map(
                                    ({ file, errors }) => (
                                      <li key={file.path}>
                                        {file.path} - {file.size} bytes
                                        <ul>
                                          {errors.map((e) => (
                                            <li key={e.code}>{e.message}</li>
                                          ))}
                                        </ul>
                                      </li>
                                    )
                                  )}
                                </ul>{" "}
                              </>
                            )}
                          </aside>
                        </Box>
                      </div>
                      {formik.touched[field.name] &&
                        Boolean(formik.errors[field.name]) && (
                          <Alert
                            severity="error"
                            variant="outlined"
                            sx={{
                              mt: 2,
                            }}
                          >
                            {formik.touched[field.name] &&
                              formik.errors[field.name]}
                          </Alert>
                        )}
                    </section>
                  </Box>
                ) : field.type === "string" ? (
                  <TextField
                    fullWidth
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                ) : field.type === "textarea" ? (
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                ) : field.type === "arrayStringColors" ? (
                  <Autocomplete
                    multiple
                    // freeSolo
                    id={field.id}
                    name={field.name}
                    // options={top100Films.map((option) => option.title)}
                    // defaultValue={[top100Films[13].title]}
                    // value={field.options.find(
                    //   (item) => item.code === formik.values[field.name]
                    // )}
                    options={field.options}
                    getOptionLabel={(option) => option.code}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            backgroundColor: option.hex,
                            mx: 2,
                          }}
                        />
                        {option.code} _ {option.name}
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        field.name,
                        newValue.map((item) => item.id)
                      );
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={`${option.code} _ ${option.name}`}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={field.label}
                        fullWidth
                        label={field.label}
                        value={formik.values[field.name]}
                        // onChange={formik.handleChange}

                        error={
                          formik.touched[field.name] &&
                          Boolean(formik.errors[field.name])
                        }
                        helperText={
                          formik.touched[field.name] &&
                          formik.errors[field.name]
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading_colorsRals ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                ) : field.type === "arrayStringWidth" ? (
                  <Autocomplete
                    multiple
                    freeSolo
                    id={field.id}
                    name={field.name}
                    // options={top100Films.map((option) => option.title)}
                    // defaultValue={[top100Films[13].title]}
                    // value={field.options.find(
                    //   (item) => item.code === formik.values[field.name]
                    // )}
                    options={field.options}
                    // getOptionLabel={(option) => option.code}

                    onChange={(event, newValue) => {
                      formik.setFieldValue(field.name, newValue);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={field.label}
                        fullWidth
                        label={field.label}
                        value={formik.values[field.name]}
                        // onChange={formik.handleChange}

                        error={
                          formik.touched[field.name] &&
                          Boolean(formik.errors[field.name])
                        }
                        helperText={
                          formik.touched[field.name] &&
                          formik.errors[field.name]
                        }
                      />
                    )}
                  />
                ) : field.type === "number" ? (
                  <TextField
                    type="number"
                    fullWidth
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                ) : null}
              </Grid>
            ))}
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  onClick={closeAndResetForm}
                  variant="outlined"
                  color="error"
                  endIcon={<CloseRounded />}
                >
                  Annuler
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<SaveRounded />}
                  loading={loading_products}
                >
                  Enregistrer
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
