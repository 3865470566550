import { createBrowserHistory } from "history";

/* Creating a history object and exporting it. */
export const history = createBrowserHistory();

/**
 * Redirects the browser to the specified location
 * @param location - The location to redirect to.
 */
export const redirect = (location) => {
  history.push(location);
  window.scrollTo(0, 0);
};
