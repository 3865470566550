import { blogTypes } from "app/redux/types";

const {
  DELETE_BLOG_FAILURE,
  DELETE_BLOG_REQUEST,
  DELETE_BLOG_SUCCESS,
  EDIT_BLOG_FAILURE,
  EDIT_BLOG_REQUEST,
  EDIT_BLOG_SUCCESS,
  GET_BLOG_FAILURE,
  GET_BLOG_REQUEST,
  GET_BLOG_SUCCESS,
  GET_BLOG_DETAILS_FAILURE,
  GET_BLOG_DETAILS_REQUEST,
  GET_BLOG_DETAILS_SUCCESS,
  POST_BLOG_FAILURE,
  POST_BLOG_REQUEST,
  POST_BLOG_SUCCESS,
  RELOAD_CONTENT_FAILURE,
  RELOAD_CONTENT_REQUEST,
  RELOAD_CONTENT_SUCCESS,
} = blogTypes;

const initState = {
  blog: [],
  blog_details: {},
  loading_blog: false,
  loading_blog_details: false,
  error_blog: null,
  error_blog_details: null,

  loading_content: false,
  error_content: null,
};

const blogReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_BLOG_REQUEST:
    case POST_BLOG_REQUEST:
    case EDIT_BLOG_REQUEST:
    case DELETE_BLOG_REQUEST:
      return {
        ...state,
        loading_blog: true,
        error_blog: null,
      };
    case GET_BLOG_FAILURE:
    case POST_BLOG_FAILURE:
    case EDIT_BLOG_FAILURE:
    case DELETE_BLOG_FAILURE:
      return {
        ...state,
        loading_blog: false,
        error_blog: action.payload,
      };

    case GET_BLOG_SUCCESS:
      return {
        ...state,
        blog: action.payload,
        loading_blog: false,
        error_blog: null,
      };

    case GET_BLOG_DETAILS_REQUEST:
      return {
        ...state,
        loading_blog_details: true,
        error_blog_details: null,
      };

    case GET_BLOG_DETAILS_FAILURE:
      return {
        ...state,
        loading_blog_details: false,
        error_blog_details: action.payload,
      };
    case GET_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        blog_details: action.payload,
        loading_blog_details: false,
        error_blog_details: null,
      };
    case POST_BLOG_SUCCESS:
      return {
        ...state,
        blog: [...state.blog, action.payload],
        loading_blog: false,
        error_blog: null,
      };
    case EDIT_BLOG_SUCCESS:
      return {
        ...state,
        blog: state.blog.map((blog) => {
          if (blog.id.toString() === action.payload.id.toString()) {
            return action.payload;
          }
          return blog;
        }),
        loading_blog: false,
        error_blog: null,
      };
    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        blog: state.blog.filter((blog) => {
          return blog.id.toString() !== action.payload.toString();
        }),
        loading_blog: false,
        error_blog: null,
      };
    case RELOAD_CONTENT_REQUEST:
      return {
        ...state,
        loading_content: true,
        error_content: null,
      };
    case RELOAD_CONTENT_FAILURE:
      return {
        ...state,
        loading_content: false,
        error_content: action.payload,
      };
    case RELOAD_CONTENT_SUCCESS:
      return {
        ...state,
        loading_content: false,
        error_content: null,
      };

    default:
      return state;
  }
};

export default blogReducer;
