import { productTypes } from "app/redux/types";

const {
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,

  EDIT_PRODUCT_FAILURE,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,

  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,

  GET_PRODUCT_DETAILS_FAILURE,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,

  POST_PRODUCT_FAILURE,
  POST_PRODUCT_REQUEST,
  POST_PRODUCT_SUCCESS,

  RELOAD_CONTENT_FAILURE,
  RELOAD_CONTENT_REQUEST,
  RELOAD_CONTENT_SUCCESS,
} = productTypes;

const initState = {
  products: [],
  products_details: {},
  loading_products: false,
  loading_products_details: false,
  error_products: null,
  error_products_details: null,

  loading_content: false,
  error_content: null,
};

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
    case POST_PRODUCT_REQUEST:
    case EDIT_PRODUCT_REQUEST:
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading_products: true,
        error_products: null,
      };
    case GET_PRODUCTS_FAILURE:
    case POST_PRODUCT_FAILURE:
    case EDIT_PRODUCT_FAILURE:
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading_products: false,
        error_products: action.payload,
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loading_products: false,
        error_products: null,
      };

    case GET_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loading_products_details: true,
        error_products_details: null,
      };

    case GET_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        loading_products_details: false,
        error_products_details: action.payload,
      };
    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        products_details: action.payload,
        loading_products_details: false,
        error_products_details: null,
      };
    case POST_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
        loading_products: false,
        error_products: null,
      };
    case EDIT_PRODUCT_SUCCESS:
      let updated_products = state.products.map((product) => {
        if (product.id === action.payload.id) {
          return {
            ...product,
            ...action.payload,
          };
        }
        return product;
      });

      return {
        ...state,
        products: updated_products,
        loading_products: false,
        error_products: null,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter((product) => {
          return product.id.toString() !== action.payload.toString();
        }),
        loading_products: false,
        error_products: null,
      };
    case RELOAD_CONTENT_REQUEST:
      return {
        ...state,
        loading_content: true,
        error_content: null,
      };
    case RELOAD_CONTENT_FAILURE:
      return {
        ...state,
        loading_content: false,
        error_content: action.payload,
      };
    case RELOAD_CONTENT_SUCCESS:
      return {
        ...state,
        loading_content: false,
        error_content: null,
      };

    default:
      return state;
  }
};

export default productReducer;
