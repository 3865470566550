import { createStore, applyMiddleware } from "redux";
import reducers from "app/redux/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

const loggerMiddleware = (store) => (next) => (action) => {
  // console.log(
  //   "👽 ~ file: store.js ~ line 8 ~ loggerMiddleware ~ dispatching action",
  //   action
  // );
  let result = next(action);
  // console.log(
  //   "👽 ~ file: store.js ~ line 13 ~ loggerMiddleware ~ next state",
  //   store.getState()
  // );
  return result;
};

// const round = (num) => Math.round(num * 100) / 100;

const monitorReducerEnhancer = (createStore) => (reducer, enhancer) => {
  const monitoredReducer = (state, action) => {
    // const start = performance.now();
    const newState = reducer(state, action);
    // const end = performance.now();
    // const diff = round(end - start);
    // console.log(
    //   "👽 ~ file: store.js ~ line 28% ~ monitorReducerEnhancer ~~ reducer process time",
    //   diff
    // );
    return newState;
  };
  return createStore(monitoredReducer, enhancer);
};

const configureStore = () => {
  const middlewares = [loggerMiddleware, thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducerEnhancer];

  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(reducers, composedEnhancers);

  return store;
};

const store = configureStore();

export default store;
