import { authTypes } from "app/redux/types";

const {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,

  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,

  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,

  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  
} = authTypes;

const initState = {
  token: localStorage.getItem("ses_token") || null,
  token_expiration_date:
    localStorage.getItem("ses_token_expiration_date") || null,
  user: localStorage.getItem("ses_user_profile")
    ? JSON.parse(localStorage.getItem("ses_user_profile"))
    : null,
  auth_error: null,
  auth_loading: {
    signIn: false,
    getUserProfile: false,
    refreshToken: false,
    logout: false,
  },
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {
        ...state,
        auth_loading: {
          ...state.auth_loading,
          signIn: true,
        },
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        token_expiration_date: action.expiration_date,
        auth_loading: {
          ...state.auth_loading,
          signIn: false,
        },
      };
    case SIGNIN_FAILURE:
      return {
        ...state,
        auth_error: action.payload,
        token: null,
        token_expiration_date: null,
        user: null,
        auth_loading: {
          ...state.auth_loading,
          signIn: false,
        },
      };
    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        auth_loading: {
          ...state.auth_loading,
          getUserProfile: true,
        },
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        auth_loading: {
          ...state.auth_loading,
          getUserProfile: false,
        },
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        auth_error: action.payload,
        token: null,
        token_expiration_date: null,
        user: null,
        auth_loading: {
          ...state.auth_loading,
          getUserProfile: false,
        },
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        auth_loading: {
          ...state.auth_loading,
          logout: true,
        },
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        user: null,
        auth_loading: {
          ...state.auth_loading,
          logout: false,
        },
      };
    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        auth_loading: {
          ...state.auth_loading,
          refreshToken: true,
        },
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        auth_error: action.payload,
        token: null,
        token_expiration_date: null,
        user: null,
        auth_loading: {
          ...state.auth_loading,
          refreshToken: false,
        },
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        token_expiration_date: action.expiration_date,
        auth_loading: {
          ...state.auth_loading,
          refreshToken: false,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
