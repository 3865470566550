import { membersType } from "app/redux/types";

const {
  DELETE_MEMBER_FAILURE,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,

  EDIT_MEMBER_FAILURE,
  EDIT_MEMBER_REQUEST,
  EDIT_MEMBER_SUCCESS,

  POST_MEMBER_FAILURE,
  POST_MEMBER_REQUEST,
  POST_MEMBER_SUCCESS,

  GET_MEMBERS_FAILURE,
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_SUCCESS,

  GET_MEMBERS_DETAILS_REQUEST,
  GET_MEMBERS_DETAILS_FAILURE,
  GET_MEMBERS_DETAILS_SUCCESS,
} = membersType;

const initState = {
  members: [],
  member_detail: null,
  loading_member: false,
  loading_members: false,
  error_members: null,
  error_member: null,
};

const membersReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload,
        loading_members: false,
        loading_member: false,
        error_member: null,
        error_members: null,
      };
    case POST_MEMBER_SUCCESS:
      return {
        ...state,
        members: [...state.members, action.payload],
        loading_members: false,
        loading_member: false,
        error_member: null,
        error_members: null,
      };

    case EDIT_MEMBER_SUCCESS:
      return {
        ...state,
        members: state?.members.map((member) => {
          if (member.id.toString() === action.payload.id.toString()) {
            return action.payload;
          }
          return {
            ...member,
            order:
              member.order.toString() === action.payload.order.toString()
                ? member.order + 1
                : member.order,
          };
        }),
        loading_members: false,
        loading_member: false,
        error_member: null,
        error_members: null,
      };
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.filter((member) => {
          return member.id.toString() !== action.id.toString();
        }),
        loading_members: false,
        loading_member: false,
        error_member: null,
        error_members: null,
      };
    case GET_MEMBERS_DETAILS_SUCCESS:
      return {
        ...state,
        member_detail: action.payload,
        loading_members: false,
        loading_member: false,
        error_member: null,
        error_members: null,
      };
    case GET_MEMBERS_DETAILS_REQUEST:
      return {
        ...state,
        loading_member: true,
      };
    case GET_MEMBERS_DETAILS_FAILURE:
      return {
        ...state,
        error_member: action.payload,
      };

    case GET_MEMBERS_REQUEST:
    case POST_MEMBER_REQUEST:
    case EDIT_MEMBER_REQUEST:
    case DELETE_MEMBER_REQUEST:
      return {
        ...state,
        loading_members: true,
      };
    case EDIT_MEMBER_FAILURE:
    case POST_MEMBER_FAILURE:
    case GET_MEMBERS_FAILURE:
    case DELETE_MEMBER_FAILURE:
      return {
        ...state,
        loading_members: false,
        error_members: action.payload,
      };
    default:
      return state;
  }
};

export default membersReducer;
