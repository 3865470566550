/* REDUCERS */
import { combineReducers } from "redux";
import snacksReducer from "app/redux/reducers/snacks";
import authReducer from "app/redux/reducers/auth";
import membersReducer from "app/redux/reducers/members";
import contactReducer from "app/redux/reducers/contact";
import clientsReducer from "app/redux/reducers/clients";
import quotesReducer from "app/redux/reducers/quotes";
import productReducer from "app/redux/reducers/products";
import otherReducer from "app/redux/reducers/other";
import worksReducer from "app/redux/reducers/works";
import blogReducer from "app/redux/reducers/blog";
import headerReducer from "app/redux/reducers/reducers_client/header";
import productLandingReducer from "app/redux/reducers/reducers_client/products";
import membersCSReducer from "app/redux/reducers/reducers_client/members";
import clientCSReducer from "app/redux/reducers/reducers_client/clients";
import blogCSReducer from "app/redux/reducers/reducers_client/blog";
import contentReducer from "app/redux/reducers/content";

const reducers = combineReducers({
  /* admin */
  snacksState: snacksReducer,
  authState: authReducer,
  membersState: membersReducer,
  contactState: contactReducer,
  clientState: clientsReducer,
  quoteState: quotesReducer,
  productState: productReducer,
  otherState: otherReducer,
  workState: worksReducer,
  blogState: blogReducer,
  /* client */
  haderState: headerReducer,
  productClientState: productLandingReducer,
  membersCSState: membersCSReducer,
  clientCSState: clientCSReducer,
  blogCSState: blogCSReducer,

  /* Website pref */
  contentState: contentReducer,
});

export default reducers;
