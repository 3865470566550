import React from "react";
import { Box, CardActionArea, Grid, Stack, Typography } from "@mui/material";
import { redirect } from "app/helpers/historyDom";
import { DashboardRounded } from "@mui/icons-material";
import { listItems, scopes } from "app/routes/routeList";
import BreadCrumbsNav from "components/navigation/BreadCrumbsNav";
import CardDashPaper from "components/cards/CardDashPaper";
import Title from "components/Text/Title";

const Home = () => {
  const navigationBearcrumbs = [
    {
      label: "Tableau de bord",
      path: scopes.admin.home,

      icon: <DashboardRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: true,
    },
  ];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BreadCrumbsNav navigation={navigationBearcrumbs} />
      </Grid>
      <Grid item xs={12}>
        <CardDashPaper>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
          >
            <Title
              title="Tableau de bord"
              color="primary"
              sx={{
                fontWeight: "bold",
              }}
            />
            <Box
              sx={{
                width: "100%",
                height: "100%",
                px: 2,
                py: 5,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
              >
                {listItems.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Box
                      onClick={() => redirect(item.url)}
                      component={CardActionArea}
                      sx={{
                        width: "100%",
                        height: "100%",
                        px: 2,
                        py: 6,
                        borderRadius: 5,
                        border: "1px solid #e0e0e0",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={2}
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{
                            fontWeight: 500,
                          }}
                          align="center"
                        >
                          {item.text}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.icon}
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </CardDashPaper>
      </Grid>
    </Grid>
  );
};

export default Home;
