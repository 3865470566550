import { products_clients_types } from "app/redux/types_client";

const {
  GET_PRODUCT_CLIENT_REQUEST,
  GET_PRODUCT_CLIENT_FAILURE,
  GET_PRODUCT_CLIENT_SUCCESS,
  POST_PRODUCT_CLIENT_FAILURE,
  POST_PRODUCT_CLIENT_REQUEST,
  POST_PRODUCT_CLIENT_SUCCESS,
  GET_PRODUCT_DETAILS_CS_FAILURE,
  GET_PRODUCT_DETAILS_CS_REQUEST,
  GET_PRODUCT_DETAILS_CS_SUCCESS,
  GET_PRODUCT_CLIENT_WITH_OTHER_FAILURE,
  GET_PRODUCT_CLIENT_WITH_OTHER_REQUEST,
  GET_PRODUCT_CLIENT_WITH_OTHER_SUCCESS,
} = products_clients_types;

const initState = {
  products_with_other: [],
  products: [],
  product_details: {},
  loading_product_details: false,
  loading_products: false,
  loading_product_with_other: false,
  error_products: null,
  error_products_details: null,
};

const productLandingReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PRODUCT_CLIENT_WITH_OTHER_REQUEST:
      return {
        ...state,
        loading_product_with_other: true,
      };
    case GET_PRODUCT_CLIENT_WITH_OTHER_SUCCESS:
      return {
        ...state,
        products_with_other: action.payload,
        loading_product_with_other: false,
      };
    case GET_PRODUCT_CLIENT_WITH_OTHER_FAILURE:
      return {
        ...state,
        error_products_with_other: action.payload,
        loading_product_with_other: false,
      };
    case GET_PRODUCT_DETAILS_CS_REQUEST:
      return {
        ...state,
        loading_product_details: true,
        error_products_details: null,
      };
    case GET_PRODUCT_DETAILS_CS_SUCCESS:
      return {
        ...state,
        product_details: action.payload,
        loading_product_details: false,
        error_products_details: null,
      };
    case GET_PRODUCT_DETAILS_CS_FAILURE:
      return {
        ...state,
        loading_product_details: false,
        error_products_details: action.payload,
      };
    case POST_PRODUCT_CLIENT_REQUEST:
    case GET_PRODUCT_CLIENT_REQUEST:
      return {
        ...state,
        loading_products: true,
        error_products: null,
      };
    case GET_PRODUCT_CLIENT_SUCCESS:
      return {
        ...state,
        loading_products: false,
        products: action.payload,
      };
    case POST_PRODUCT_CLIENT_SUCCESS:
      return {
        ...state,
        loading_products: false,
        error_products: null,
      };
    case GET_PRODUCT_CLIENT_FAILURE:
    case POST_PRODUCT_CLIENT_FAILURE:
      return {
        ...state,
        loading_products: false,
        error_products: action.payload,
      };
    default:
      return state;
  }
};

export default productLandingReducer;
