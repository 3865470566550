module.exports = {
  /* AUTH */
  endpoint_login: `${process.env.REACT_APP_URL}auth/login`,
  endpoint_refresh_token: `${process.env.REACT_APP_URL}auth/refresh`,
  endpoint_get_user_profile: `${process.env.REACT_APP_URL}auth/me`,

  /* ADMIN _ MEMBERS */
  endpoint_get_members: `${process.env.REACT_APP_URL}members`,
  endpoint_get_members_details: `${process.env.REACT_APP_URL}members/:id`,
  endpoint_post_member: `${process.env.REACT_APP_URL}members`,
  endpoint_edit_member: `${process.env.REACT_APP_URL}members/:id`,
  endpoint_delete_member: `${process.env.REACT_APP_URL}members/:id`,

  /* ADMIN  CONTACT */
  endpoint_get_contact: `${process.env.REACT_APP_URL}contacts`,
  endpoint_delete_contact: `${process.env.REACT_APP_URL}contacts/:id`,
  endppoint_post_contact: `${process.env.REACT_APP_URL}contacts`,

  /* ADMIN CLIENTS */
  endpoint_get_clients: `${process.env.REACT_APP_URL}clients`,
  endpoint_get_clients_details: `${process.env.REACT_APP_URL}clients/:id`,
  endpoint_post_client: `${process.env.REACT_APP_URL}clients`,
  endpoint_edit_client: `${process.env.REACT_APP_URL}clients/:id`,
  endpoint_delete_client: `${process.env.REACT_APP_URL}clients/:id`,

  /* ADMIN ORDERS/QUOTES */
  endpoint_get_orders: `${process.env.REACT_APP_URL}orders`,
  endpoint_get_orders_details: `${process.env.REACT_APP_URL}orders/:id`,
  endpoint_delete_order: `${process.env.REACT_APP_URL}orders/:id`,
  endpoint_post_order: `${process.env.REACT_APP_URL}orders`,

  /* ADMIN PRODUCTS */
  endpoint_get_products: `${process.env.REACT_APP_URL}products`,
  endpoint_get_products_details: `${process.env.REACT_APP_URL}products/:id`,
  endpoint_post_product: `${process.env.REACT_APP_URL}products`,
  endpoint_edit_product: `${process.env.REACT_APP_URL}products/:id`,
  endpoint_delete_product: `${process.env.REACT_APP_URL}products/:id`,

  /* OTHER */
  enpoint_get_wilayas: `${process.env.REACT_APP_URL}other/wilayas`,
  endpoint_get_rals_colors: `${process.env.REACT_APP_URL}other/rals`,

  /* MEDIA */
  endpoint_delete_media: `${process.env.REACT_APP_URL}medias/:id`,

  /* REALISATIONS */
  endpoint_get_work: `${process.env.REACT_APP_URL}works`,
  endpoint_get_work_details: `${process.env.REACT_APP_URL}works/:id`,
  endpoint_post_work: `${process.env.REACT_APP_URL}works`,
  endpoint_put_work: `${process.env.REACT_APP_URL}works/:id`,
  endpoint_delete_work: `${process.env.REACT_APP_URL}works/:id`,

  /* BLOG */
  endpoint_get_blog: `${process.env.REACT_APP_URL}articles`,
  endpoint_get_blog_details: `${process.env.REACT_APP_URL}articles/:id`,
  endpoint_post_blog: `${process.env.REACT_APP_URL}articles`,
  endpoint_put_blog: `${process.env.REACT_APP_URL}articles/:id`,
  endpoint_delete_blog: `${process.env.REACT_APP_URL}articles/:id`,

  /* MAP GOOGLE URL */

  map_url: `https://maps.googleapis.com/maps/api/staticmap?key=YOUR_API_KEY&center=36.71152496636228,3.0952595551757645&zoom=12&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:geometry.fill%7Ccolor:0x82c9c3%7Cvisibility:on&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=480x360`,
};
