import {
  AddRounded,
  ArtTrackRounded,
  CloseRounded,
  DashboardRounded,
  DeleteRounded,
  LoupeRounded,
  SaveRounded,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { scopes } from "app/routes/routeList";
import CardDashPaper from "components/cards/CardDashPaper";
import BreadCrumbsNav from "components/navigation/BreadCrumbsNav";
import Title from "components/Text/Title";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import CustomNoDataFound from "utils/CustomNoDataFound";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWork,
  editWork,
  getWorkDetails,
  getWorks,
  postWork,
} from "app/redux/actions/worksActions";
import { useDropzone } from "react-dropzone";
import { fileTypesImages } from "utils/authFiles";
import { useFormik } from "formik";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "theme/dropZoneTheme";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { deleteMedia } from "app/redux/actions/medias";

const Root = styled("div")(({ theme }) => ({
  width: "100%",

  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const Realisations = () => {
  const workState = useSelector((state) => state.workState);
  const { works, loading_works } = workState;

  const [openDialogPost, setopenDialogPost] = useState(false);
  const [openDialogEdit, setopenDialogEdit] = useState(false);
  const [selectedWork, setselectedWork] = useState({});

  const [page, setpage] = useState(1);
  const cardPerPage = 8;

  const handleChangePage = (event, newPage) => {
    setpage(newPage);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWorks());
  }, [dispatch]);

  const navigationBearcrumbs = [
    {
      label: "Tableau de bord",
      path: scopes.admin.home,

      icon: <DashboardRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: false,
    },
    {
      label: "Réalisations",
      path: scopes.admin.realisations,

      icon: <ArtTrackRounded sx={{ mr: 0.5 }} fontSize="inherit" />,
      current: true,
    },
  ];
  const deleteRow = (params) => {
    dispatch(deleteWork(params.id));
  };

  const handleCloseDialogDetails = () => {
    setselectedWork({});
    setopenDialogEdit(false);
  };

  const handleOpenDialogDetails = (params) => {
    setselectedWork(params);
    setopenDialogEdit(true);
  };

  const handleOpenDialogPost = () => {
    setopenDialogPost(true);
  };
  const handleCloseDialogPost = () => {
    setopenDialogPost(false);
  };
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BreadCrumbsNav navigation={navigationBearcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "flex-end",
              },
              width: "100%",
            }}
          >
            <Button
              endIcon={<AddRounded />}
              variant="text"
              color="info"
              onClick={handleOpenDialogPost}
            >
              Ajouter une nouvelle réalisation
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <CardDashPaper>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Title
                title="Réalisations"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              />
              {loading_works ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    py: 10,
                    px: 2,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : works.length > 0 ? (
                <Box
                  sx={{
                    px: 2,
                    py: 2,
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={4}
                  >
                    {works
                      ?.slice((page - 1) * cardPerPage, page * cardPerPage)
                      ?.sort((a, b) => {
                        return a.order - b.order;
                      })
                      ?.map((work, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                          <Box
                            component={Card}
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: 2,
                            }}
                          >
                            <CardMedia
                              component="img"
                              alt={work?.images[0]?.url.name}
                              height={200}
                              image={work?.images[0]?.url}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                                justifyContent: "space-between",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="subtitle1"
                                  component="div"
                                  sx={{
                                    fontWeight: 400,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {work.title_fr}
                                </Typography>
                              </CardContent>

                              <CardActions
                                disableSpacing
                                sx={{
                                  // backgroundColor: "red",
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "stretch",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Tooltip title="Supprimer">
                                    <IconButton
                                      size="small"
                                      onClick={() => deleteRow(work)}
                                      color="error"
                                    >
                                      <DeleteRounded />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Voir les détails / Modifier">
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleOpenDialogDetails(work)
                                      }
                                      color="info"
                                    >
                                      <LoupeRounded />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </CardActions>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 2,
                          width: "100%",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(works?.length / cardPerPage)}
                          page={page}
                          onChange={handleChangePage}
                          variant="outlined"
                          color="primary"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    py: 10,
                  }}
                >
                  <CustomNoDataFound />
                </Box>
              )}
            </Stack>
          </CardDashPaper>
        </Grid>
      </Grid>
      <DialogPost
        open={openDialogPost}
        title="Ajouter une nouvelle réalisation"
        handleClose={handleCloseDialogPost}
      />
      <DialogUpdate
        open={openDialogEdit}
        title="Modifier une réalisation"
        handleClose={handleCloseDialogDetails}
        selectedRow={selectedWork}
      />
    </Fragment>
  );
};

export default Realisations;

const DialogPost = ({ open, title, handleClose }) => {
  const workState = useSelector((state) => state.workState);
  const { loading_works } = workState;
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  let dropZoneImages = useDropzone({
    accept: fileTypesImages,
    // maxFiles: 1,
    multiple: true,
    onDropAccepted: (files) => {
      formik.setFieldValue("images", files);
    },
  });

  const style_images = useMemo(
    () => ({
      ...baseStyle,
      ...(dropZoneImages.isFocused ? focusedStyle : {}),
      ...(dropZoneImages.isDragAccept ? acceptStyle : {}),
      ...(dropZoneImages.isDragReject ? rejectStyle : {}),
    }),
    [
      dropZoneImages.isFocused,
      dropZoneImages.isDragAccept,
      dropZoneImages.isDragReject,
    ]
  );

  const validationSchema = yup.object({
    title_fr: yup
      .string()
      .required("Cette information est obligatoire")
      .max(150, "Le titre ne doit pas dépasser 150 caractères"),
    title_en: yup
      .string()
      .required("Cette information est obligatoire")
      .max(150, "Le titre ne doit pas dépasser 150 caractères"),

    title_ar: yup
      .string()
      .required("Cette information est obligatoire")
      .max(150, "Le titre ne doit pas dépasser 150 caractères"),

    images: yup
      .array()
      .of(yup.mixed())
      .min(1, "Vous devez ajouter au moins une image")
      .required("Vous devez ajouter au moins une image"),
  });

  const formFields = useMemo(() => {
    return [
      {
        name: "title_fr",
        id: "title_fr",
        label: "Titre (Français)",
        type: "string",
        initialValue: "",
      },
      {
        name: "title_en",
        id: "title_en",
        label: "Titre (Anglais)",
        type: "string",
        initialValue: "",
      },
      {
        name: "title_ar",
        id: "title_ar",
        label: "Titre (Arabe)",
        type: "string",
        initialValue: "",
      },
      {
        name: "images",
        id: "images",
        label: "Images",
        instructions:
          "Glisser et déposer des images ici, ou cliquer pour en sélectionner une ou plusieurs images",
        type: "arrayImages",
        initialValue: [],
      },
    ];
  }, []);

  const initialValues = useMemo(() => {
    return formFields.reduce((acc, field) => {
      acc[field.name] = field.initialValue;
      return acc;
    }, {});
  }, [formFields]);

  let formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(postWork(values, closeAndResetForm));
    },
  });

  const closeAndResetForm = () => {
    formik.resetForm();
    removeAllFiles();
    handleClose();
  };

  const removeAllFiles = () => {
    dropZoneImages.acceptedFiles.length = 0;
    dropZoneImages.acceptedFiles.splice(0, dropZoneImages.acceptedFiles.length);
    dropZoneImages.inputRef.current.value = "";
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={closeAndResetForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          // noValidate
          sx={{ mt: 1 }}
          onSubmit={formik.handleSubmit}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
          >
            {formFields.map((field) => (
              <Grid item key={field.id} xs={12}>
                {field.type === "arrayImages" ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Root>
                      <Divider>
                        <Chip label="Images" />
                      </Divider>
                    </Root>
                    <Box
                      sx={{
                        my: 3,
                      }}
                    />
                    <section className="container">
                      <div
                        {...dropZoneImages.getRootProps({
                          style: style_images,
                        })}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box
                          component="div"
                          {...dropZoneImages.getRootProps({
                            className: "dropzone",
                          })}
                          sx={{
                            p: "30px",
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={2}
                          >
                            {dropZoneImages.acceptedFiles.length > 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  overflowX: "scroll",
                                }}
                              >
                                {dropZoneImages.acceptedFiles?.map(
                                  (file, index) => {
                                    return (
                                      <Avatar
                                        key={index}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        variant="rounded"
                                        sx={{ width: 80, height: 80, p: 2 }}
                                      />
                                    );
                                  }
                                )}
                              </Box>
                            )}
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <input {...dropZoneImages.getInputProps()} />
                              <p>{field.instructions}</p>
                              {/* <em>
                            {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                          </em> */}
                            </div>
                          </Stack>
                          <Box
                            sx={{
                              my: 2,
                            }}
                          />
                          <aside>
                            {dropZoneImages.fileRejections.length > 0 && (
                              <>
                                <h4>image refusé</h4>
                                <ul>
                                  {dropZoneImages.fileRejections.map(
                                    ({ file, errors }) => (
                                      <li key={file.path}>
                                        {file.path} - {file.size} bytes
                                        <ul>
                                          {errors.map((e) => (
                                            <li key={e.code}>{e.message}</li>
                                          ))}
                                        </ul>
                                      </li>
                                    )
                                  )}
                                </ul>{" "}
                              </>
                            )}
                          </aside>
                        </Box>
                      </div>
                      {formik.touched[field.name] &&
                        Boolean(formik.errors[field.name]) && (
                          <Alert
                            severity="error"
                            variant="outlined"
                            sx={{
                              mt: 2,
                            }}
                          >
                            {formik.touched[field.name] &&
                              formik.errors[field.name]}
                          </Alert>
                        )}
                    </section>
                  </Box>
                ) : field.type === "string" ? (
                  <TextField
                    fullWidth
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                ) : field.type === "number" ? (
                  <TextField
                    type="number"
                    fullWidth
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                ) : null}
              </Grid>
            ))}
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  onClick={closeAndResetForm}
                  variant="outlined"
                  color="error"
                  endIcon={<CloseRounded />}
                >
                  Annuler
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<SaveRounded />}
                  loading={loading_works}
                >
                  Enregistrer
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
const DialogUpdate = ({ open, title, handleClose, selectedRow }) => {
  const workState = useSelector((state) => state.workState);
  const {
    works_details,
    loading_works,
    loading_works_details,
    loading_content,
  } = workState;

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  let dropZoneImages = useDropzone({
    accept: fileTypesImages,
    // maxFiles: 1,
    multiple: true,
    onDropAccepted: (files) => {
      formik.setFieldValue("images", files);
    },
  });

  useEffect(() => {
    if (selectedRow.id !== undefined) {
      dispatch(getWorkDetails(selectedRow?.id));
    }
  }, [dispatch, selectedRow]);

  const style_images = useMemo(
    () => ({
      ...baseStyle,
      ...(dropZoneImages.isFocused ? focusedStyle : {}),
      ...(dropZoneImages.isDragAccept ? acceptStyle : {}),
      ...(dropZoneImages.isDragReject ? rejectStyle : {}),
    }),
    [
      dropZoneImages.isFocused,
      dropZoneImages.isDragAccept,
      dropZoneImages.isDragReject,
    ]
  );

  const validationSchema = yup.object({
    title_fr: yup
      .string()
      .required("Cette information est obligatoire")
      .max(150, "Le titre ne doit pas dépasser 150 caractères"),
    title_en: yup
      .string()
      .required("Cette information est obligatoire")
      .max(150, "Le titre ne doit pas dépasser 150 caractères"),

    title_ar: yup
      .string()
      .required("Cette information est obligatoire")
      .max(150, "Le titre ne doit pas dépasser 150 caractères"),
    order: yup.number().required("Cette information est obligatoire"),

    images: yup
      .array()
      .of(yup.mixed())
      .min(1, "Vous devez ajouter au moins une image")
      .required("Vous devez ajouter au moins une image"),
  });

  let formFields = useMemo(() => {
    return [
      {
        name: "title_fr",
        id: "title_fr",
        label: "Titre (Français)",
        type: "string",
        initialValue: works_details?.title_fr,
      },
      {
        name: "title_en",
        id: "title_en",
        label: "Titre (Anglais)",
        type: "string",
        initialValue: works_details?.title_en,
      },
      {
        name: "title_ar",
        id: "title_ar",
        label: "Titre (Arabe)",
        type: "string",
        initialValue: works_details?.title_ar,
      },
      {
        name: "order",
        id: "order",
        label: "Ordre",
        type: "number",
        initialValue: works_details?.order,
      },
      {
        name: "images",
        id: "images",
        label: "Images",
        instructions:
          "Glisser et déposer des images ici, ou cliquer pour en sélectionner une ou plusieurs images",
        type: "arrayImages",
        initialValue: works_details.images,
      },
    ];
  }, [works_details]);

  let initialValues = useMemo(() => {
    return formFields.reduce((acc, field) => {
      acc[field.name] = field.initialValue;
      return acc;
    }, {});
  }, [formFields]);

  let formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        ...values,
        images: values.images.filter((image) => image instanceof File),
      };

      dispatch(editWork(payload, selectedRow, closeAndResetForm));
    },
  });

  const closeAndResetForm = () => {
    formik.resetForm();
    removeAllFiles();
    handleClose();
  };

  const removeAllFiles = () => {
    dropZoneImages.acceptedFiles.length = 0;
    dropZoneImages.acceptedFiles.splice(0, dropZoneImages.acceptedFiles.length);
    dropZoneImages.inputRef.current.value = "";
  };

  const confirmDialog = (file, field) => {
    var confirmation = window.confirm("Voulez-vous supprimer cette image ?");
    if (confirmation === true) {
      dispatch(deleteMedia(file.id));
      formFields = formFields.map((elmt) => {
        if (elmt.name === field.name) {
          elmt.initialValue = elmt.initialValue.filter(
            (f) => f.id.toString() !== file.id.toString()
          );
        }
        return elmt;
      });
      formik.setFieldValue(
        field.name,
        field.initialValue.filter(
          (item) => item.id.toString() !== file.id.toString()
        )
      );
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={closeAndResetForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {loading_works_details ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              p: 4,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            component="form"
            // noValidate
            sx={{ mt: 1 }}
            onSubmit={formik.handleSubmit}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              {formFields.map((field) => (
                <Grid item key={field.id} xs={12}>
                  {field.type === "arrayImages" ? (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Root>
                        <Divider>
                          <Chip label="Images" />
                        </Divider>
                      </Root>
                      {/* <Typography variant="subtitle1" gutterBottom align="left">
                        Images
                      </Typography> */}
                      {Array.isArray(field.initialValue) && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            overflowX: "scroll",
                            my: 3,
                          }}
                        >
                          {loading_content ? (
                            <Box
                              sx={{
                                width: "100%",
                                py: 1,
                                my: 3,
                              }}
                            >
                              <LinearProgress />
                            </Box>
                          ) : (
                            field.initialValue.map((image, index) =>
                              field.initialValue.length > 1 ? (
                                <Badge
                                  key={index}
                                  color="error"
                                  overlap="circular"
                                  badgeContent={
                                    <p
                                      onClick={() => {
                                        confirmDialog(image, field);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      X
                                    </p>
                                  }
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <Avatar
                                    src={image.url}
                                    alt={image.name}
                                    variant="rounded"
                                    sx={{
                                      width: 90,
                                      height: 90,
                                      mr: 1,
                                      mb: 1,
                                    }}
                                  />
                                </Badge>
                              ) : (
                                <Avatar
                                  key={index}
                                  src={image.url}
                                  alt={image.name}
                                  variant="rounded"
                                  sx={{
                                    width: 90,
                                    height: 90,
                                    mr: 1,
                                    mb: 1,
                                  }}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                      <section className="container">
                        <div
                          {...dropZoneImages.getRootProps({
                            style: style_images,
                          })}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Box
                            component="div"
                            {...dropZoneImages.getRootProps({
                              className: "dropzone",
                            })}
                            sx={{
                              p: "30px",
                            }}
                          >
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="stretch"
                              spacing={2}
                            >
                              {dropZoneImages.acceptedFiles.length > 0 && (
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    overflowX: "scroll",
                                  }}
                                >
                                  {dropZoneImages.acceptedFiles?.map(
                                    (file, index) => {
                                      return (
                                        <Avatar
                                          key={index}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                          variant="rounded"
                                          sx={{
                                            width: 90,
                                            height: 90,
                                            mr: 1,
                                            mb: 1,
                                          }}
                                        />
                                      );
                                    }
                                  )}
                                </Box>
                              )}
                              <div
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input {...dropZoneImages.getInputProps()} />
                                <p>{field.instructions}</p>
                                {/* <em>
                            {`<< Seuls les images de type .png .jpg .jpeg sont acceptées >>`}
                          </em> */}
                              </div>
                            </Stack>
                            <Box
                              sx={{
                                my: 2,
                              }}
                            />
                            <aside>
                              {dropZoneImages.fileRejections.length > 0 && (
                                <>
                                  <h4>image refusé</h4>
                                  <ul>
                                    {dropZoneImages.fileRejections.map(
                                      ({ file, errors }) => (
                                        <li key={file.path}>
                                          {file.path} - {file.size} bytes
                                          <ul>
                                            {errors.map((e) => (
                                              <li key={e.code}>{e.message}</li>
                                            ))}
                                          </ul>
                                        </li>
                                      )
                                    )}
                                  </ul>{" "}
                                </>
                              )}
                            </aside>
                          </Box>
                        </div>
                        {formik.touched[field.name] &&
                          Boolean(formik.errors[field.name]) && (
                            <Alert
                              severity="error"
                              variant="outlined"
                              sx={{
                                mt: 2,
                              }}
                            >
                              {formik.touched[field.name] &&
                                formik.errors[field.name]}
                            </Alert>
                          )}
                      </section>
                    </Box>
                  ) : field.type === "string" ? (
                    <TextField
                      fullWidth
                      id={field.id}
                      name={field.name}
                      label={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.name] &&
                        Boolean(formik.errors[field.name])
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  ) : field.type === "number" ? (
                    <TextField
                      type="number"
                      fullWidth
                      id={field.id}
                      name={field.name}
                      label={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.name] &&
                        Boolean(formik.errors[field.name])
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  ) : null}
                </Grid>
              ))}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    onClick={closeAndResetForm}
                    variant="outlined"
                    color="error"
                    endIcon={<CloseRounded />}
                  >
                    Annuler
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SaveRounded />}
                    loading={loading_works}
                  >
                    Enregistrer
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
