/* Notifications Actions nominations */
export const snacksTypes = {
  ENQUEUE_SNACK: "ENQUEUE_SNACK",
  CLOSE_SNACK: "CLOSE_SNACK",
  REMOVE_SNACK: "REMOVE_SNACK",
};

export const authTypes = {
  SIGNIN_REQUEST: "SIGNIN_REQUEST",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "SIGNIN_FAILURE",
  GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",
  GET_USER_PROFILE_REQUEST: "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",
  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
};

export const membersType = {
  GET_MEMBERS_REQUEST: "GET_MEMBERS_REQUEST",
  GET_MEMBERS_SUCCESS: "GET_MEMBERS_SUCCESS",
  GET_MEMBERS_FAILURE: "GET_MEMBERS_FAILURE",

  GET_MEMBERS_DETAILS_REQUEST: "GET_MEMBERS_DETAILS_REQUEST",
  GET_MEMBERS_DETAILS_SUCCESS: "GET_MEMBERS_DETAILS_SUCCESS",
  GET_MEMBERS_DETAILS_FAILURE: "GET_MEMBERS_DETAILS_FAILURE",

  POST_MEMBER_REQUEST: "POST_MEMBER_REQUEST",
  POST_MEMBER_SUCCESS: "POST_MEMBER_SUCCESS",
  POST_MEMBER_FAILURE: "POST_MEMBER_FAILURE",

  EDIT_MEMBER_REQUEST: "EDIT_MEMBER_REQUEST",
  EDIT_MEMBER_SUCCESS: "EDIT_MEMBER_SUCCESS",
  EDIT_MEMBER_FAILURE: "EDIT_MEMBER_FAILURE",

  DELETE_MEMBER_REQUEST: "DELETE_MEMBER_REQUEST",
  DELETE_MEMBER_SUCCESS: "DELETE_MEMBER_SUCCESS",
  DELETE_MEMBER_FAILURE: "DELETE_MEMBER_FAILURE",
};

export const clientsType = {
  GET_CLIENTS_REQUEST: "GET_CLIENTS_REQUEST",
  GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAILURE: "GET_CLIENTS_FAILURE",

  POST_CLIENT_REQUEST: "POST_CLIENT_REQUEST",
  POST_CLIENT_SUCCESS: "POST_CLIENT_SUCCESS",
  POST_CLIENT_FAILURE: "POST_CLIENT_FAILURE",

  GET_CLIENT_DETAILS_REQUEST: "GET_CLIENT_DETAILS_REQUEST",
  GET_CLIENT_DETAILS_SUCCESS: "GET_CLIENT_DETAILS_SUCCESS",
  GET_CLIENT_DETAILS_FAILURE: "GET_CLIENT_DETAILS_FAILURE",

  EDIT_CLIENT_REQUEST: "EDIT_CLIENT_REQUEST",
  EDIT_CLIENT_SUCCESS: "EDIT_CLIENT_SUCCESS",
  EDIT_CLIENT_FAILURE: "EDIT_CLIENT_FAILURE",

  DELETE_CLIENT_REQUEST: "DELETE_CLIENT_REQUEST",
  DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAILURE: "DELETE_CLIENT_FAILURE",
};

export const contactTypes = {
  GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_FAILURE: "GET_CONTACT_FAILURE",

  DELETE_CONTACT_REQUEST: "DELETE_CONTACT_REQUEST",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_FAILURE: "DELETE_CONTACT_FAILURE",
};

export const quotesTypes = {
  GET_QUOTES_REQUEST: "GET_QUOTES_REQUEST",
  GET_QUOTES_SUCCESS: "GET_QUOTES_SUCCESS",
  GET_QUOTES_FAILURE: "GET_QUOTES_FAILURE",

  DELETE_QUOTE_REQUEST: "DELETE_QUOTE_REQUEST",
  DELETE_QUOTE_SUCCESS: "DELETE_QUOTE_SUCCESS",
  DELETE_QUOTE_FAILURE: "DELETE_QUOTE_FAILURE",

  GET_QUOTES_DETAILS_REQUEST: "GET_QUOTES_DETAILS_REQUEST",
  GET_QUOTES_DETAILS_SUCCESS: "GET_QUOTES_DETAILS_SUCCESS",
  GET_QUOTES_DETAILS_FAILURE: "GET_QUOTES_DETAILS_FAILURE",
};

export const productTypes = {
  GET_PRODUCTS_REQUEST: "GET_PRODUCTS_REQUEST",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

  GET_PRODUCT_DETAILS_REQUEST: "GET_PRODUCT_DETAILS_REQUEST",
  GET_PRODUCT_DETAILS_SUCCESS: "GET_PRODUCT_DETAILS_SUCCESS",
  GET_PRODUCT_DETAILS_FAILURE: "GET_PRODUCT_DETAILS_FAILURE",

  POST_PRODUCT_REQUEST: "POST_PRODUCT_REQUEST",
  POST_PRODUCT_SUCCESS: "POST_PRODUCT_SUCCESS",
  POST_PRODUCT_FAILURE: "POST_PRODUCT_FAILURE",

  EDIT_PRODUCT_REQUEST: "EDIT_PRODUCT_REQUEST",
  EDIT_PRODUCT_SUCCESS: "EDIT_PRODUCT_SUCCESS",
  EDIT_PRODUCT_FAILURE: "EDIT_PRODUCT_FAILURE",

  DELETE_PRODUCT_REQUEST: "DELETE_PRODUCT_REQUEST",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",

  RELOAD_CONTENT_REQUEST: "RELOAD_CONTENT_REQUEST",
  RELOAD_CONTENT_SUCCESS: "RELOAD_CONTENT_SUCCESS",
  RELOAD_CONTENT_FAILURE: "RELOAD_CONTENT_FAILURE",
};

export const worksTypes = {
  GET_WORKS_REQUEST: "GET_WORKS_REQUEST",
  GET_WORKS_SUCCESS: "GET_WORKS_SUCCESS",
  GET_WORKS_FAILURE: "GET_WORKS_FAILURE",

  GET_WORK_DETAILS_REQUEST: "GET_WORK_DETAILS_REQUEST",
  GET_WORK_DETAILS_SUCCESS: "GET_WORK_DETAILS_SUCCESS",
  GET_WORK_DETAILS_FAILURE: "GET_WORK_DETAILS_FAILURE",

  POST_WORK_REQUEST: "POST_WORK_REQUEST",
  POST_WORK_SUCCESS: "POST_WORK_SUCCESS",
  POST_WORK_FAILURE: "POST_WORK_FAILURE",

  EDIT_WORK_REQUEST: "EDIT_WORK_REQUEST",
  EDIT_WORK_SUCCESS: "EDIT_WORK_SUCCESS",
  EDIT_WORK_FAILURE: "EDIT_WORK_FAILURE",

  DELETE_WORK_REQUEST: "DELETE_WORK_REQUEST",
  DELETE_WORK_SUCCESS: "DELETE_WORK_SUCCESS",
  DELETE_WORK_FAILURE: "DELETE_WORK_FAILURE",

  RELOAD_CONTENT_REQUEST: "RELOAD_CONTENT_REQUEST",
  RELOAD_CONTENT_SUCCESS: "RELOAD_CONTENT_SUCCESS",
  RELOAD_CONTENT_FAILURE: "RELOAD_CONTENT_FAILURE",
};

export const blogTypes = {
  GET_BLOG_REQUEST: "GET_BLOGS_REQUEST",
  GET_BLOG_SUCCESS: "GET_BLOGS_SUCCESS",
  GET_BLOG_FAILURE: "GET_BLOGS_FAILURE",

  GET_BLOG_DETAILS_REQUEST: "GET_BLOG_DETAILS_REQUEST",
  GET_BLOG_DETAILS_SUCCESS: "GET_BLOG_DETAILS_SUCCESS",
  GET_BLOG_DETAILS_FAILURE: "GET_BLOG_DETAILS_FAILURE",

  POST_BLOG_REQUEST: "POST_BLOG_REQUEST",
  POST_BLOG_SUCCESS: "POST_BLOG_SUCCESS",
  POST_BLOG_FAILURE: "POST_BLOG_FAILURE",

  EDIT_BLOG_REQUEST: "EDIT_BLOG_REQUEST",
  EDIT_BLOG_SUCCESS: "EDIT_BLOG_SUCCESS",
  EDIT_BLOG_FAILURE: "EDIT_BLOG_FAILURE",

  DELETE_BLOG_REQUEST: "DELETE_BLOG_REQUEST",
  DELETE_BLOG_SUCCESS: "DELETE_BLOG_SUCCESS",
  DELETE_BLOG_FAILURE: "DELETE_BLOG_FAILURE",

  RELOAD_CONTENT_REQUEST: "RELOAD_CONTENT_REQUEST",
  RELOAD_CONTENT_SUCCESS: "RELOAD_CONTENT_SUCCESS",
  RELOAD_CONTENT_FAILURE: "RELOAD_CONTENT_FAILURE",
};

export const otherTypes = {
  GET_WILAYAS_REQUEST: "GET_WILAYAS_REQUEST",
  GET_WILAYAS_SUCCESS: "GET_WILAYAS_SUCCESS",
  GET_WILAYAS_FAILURE: "GET_WILAYAS_FAILURE",

  GET_COLORS_RALS_REQUEST: "GET_COLORS_RALS_REQUEST",
  GET_COLORS_RALS_SUCCESS: "GET_COLORS_RALS_SUCCESS",
  GET_COLORS_RALS_FAILURE: "GET_COLORS_RALS_FAILURE",
};

export const langType = {
  CHANGE_LANGUAGE_REQUEST: "CHANGE_LANGUAGE_REQUEST",
  CHANGE_LANGUAGE_SUCCESS: "CHANGE_LANGUAGE_SUCCESS",
  CHANGE_LANGUAGE_FAILURE: "CHANGE_LANGUAGE_FAILURE",
};
