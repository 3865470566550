import { worksTypes } from "app/redux/types";

const {
  DELETE_WORK_FAILURE,
  DELETE_WORK_REQUEST,
  DELETE_WORK_SUCCESS,
  EDIT_WORK_FAILURE,
  EDIT_WORK_REQUEST,
  EDIT_WORK_SUCCESS,
  GET_WORKS_FAILURE,
  GET_WORKS_REQUEST,
  GET_WORKS_SUCCESS,
  GET_WORK_DETAILS_FAILURE,
  GET_WORK_DETAILS_REQUEST,
  GET_WORK_DETAILS_SUCCESS,
  POST_WORK_FAILURE,
  POST_WORK_REQUEST,
  POST_WORK_SUCCESS,
  RELOAD_CONTENT_FAILURE,
  RELOAD_CONTENT_REQUEST,
  RELOAD_CONTENT_SUCCESS,
} = worksTypes;

const initState = {
  works: [],
  works_details: {},
  loading_works: false,
  loading_works_details: false,
  error_works: null,
  error_works_details: null,

  loading_content: false,
  error_content: null,
};

const worksReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_WORKS_REQUEST:
    case POST_WORK_REQUEST:
    case EDIT_WORK_REQUEST:
    case DELETE_WORK_REQUEST:
      return {
        ...state,
        loading_works: true,
        error_works: null,
      };
    case GET_WORKS_FAILURE:
    case POST_WORK_FAILURE:
    case EDIT_WORK_FAILURE:
    case DELETE_WORK_FAILURE:
      return {
        ...state,
        loading_works: false,
        error_works: action.payload,
      };

    case GET_WORKS_SUCCESS:
      return {
        ...state,
        works: action.payload,
        loading_works: false,
        error_works: null,
      };

    case GET_WORK_DETAILS_REQUEST:
      return {
        ...state,
        loading_works_details: true,
        error_works_details: null,
      };

    case GET_WORK_DETAILS_FAILURE:
      return {
        ...state,
        loading_works_details: false,
        error_works_details: action.payload,
      };
    case GET_WORK_DETAILS_SUCCESS:
      return {
        ...state,
        works_details: action.payload,
        loading_works_details: false,
        error_works_details: null,
      };
    case POST_WORK_SUCCESS:
      return {
        ...state,
        works: [...state.works, action.payload],
        loading_works: false,
        error_works: null,
      };
    case EDIT_WORK_SUCCESS:
      return {
        ...state,
        works: state.works.map((work) => {
          if (work.id.toString() === action.payload.id.toString()) {
            return action.payload;
          }
          return {
            ...work,
            order:
              work.order.toString() === action.payload.order.toString()
                ? work.order + 1
                : work.order,
          };
        }),
        loading_works: false,
        error_works: null,
      };
    case DELETE_WORK_SUCCESS:
      return {
        ...state,
        works: state.works.filter((work) => {
          return work.id.toString() !== action.payload.toString();
        }),
        loading_works: false,
        error_works: null,
      };
    case RELOAD_CONTENT_REQUEST:
      return {
        ...state,
        loading_content: true,
        error_content: null,
      };
    case RELOAD_CONTENT_FAILURE:
      return {
        ...state,
        loading_content: false,
        error_content: action.payload,
      };
    case RELOAD_CONTENT_SUCCESS:
      return {
        ...state,
        loading_content: false,
        error_content: null,
      };

    default:
      return state;
  }
};

export default worksReducer;
