import { blogCSTypes } from "app/redux/types_client";

const {
  GET_BLOG_CS_REQUEST,
  GET_BLOG_CS_FAILURE,
  GET_BLOG_CS_SUCCESS,

  GET_BLOG_DETAILS_CS_FAILURE,
  GET_BLOG_DETAILS_CS_REQUEST,
  GET_BLOG_DETAILS_CS_SUCCESS,
} = blogCSTypes;

const initState = {
  blog: [],
  blog_details: {},
  loading_blog_details: false,
  loading_blog: false,
  error_blog: null,
  error_blog_details: null,
};

const blogCSReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_BLOG_CS_REQUEST:
      return {
        ...state,
        loading_blog: true,
        error_blog: null,
      };
    case GET_BLOG_CS_SUCCESS:
      return {
        ...state,
        loading_blog: false,
        blog: action.payload,
        error_blog: null,
      };
    case GET_BLOG_CS_FAILURE:
      return {
        ...state,
        loading_blog: false,
        error_blog: action.payload,
      };
    case GET_BLOG_DETAILS_CS_REQUEST:
      return {
        ...state,
        loading_blog_details: true,
        error_blog_details: null,
      };
    case GET_BLOG_DETAILS_CS_SUCCESS:
      return {
        ...state,
        loading_blog_details: false,
        blog_details: action.payload,
        error_blog_details: null,
      };
    case GET_BLOG_DETAILS_CS_FAILURE:
      return {
        ...state,
        loading_blog_details: false,
        error_blog_details: action.payload,
      };
    default:
      return state;
  }
};

export default blogCSReducer;
