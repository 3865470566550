import { endpoint_get_rals_colors, enpoint_get_wilayas } from "app/API/config";
import { otherTypes } from "app/redux/types";
import axios from "axios";

const {
  GET_COLORS_RALS_FAILURE,
  GET_COLORS_RALS_REQUEST,
  GET_COLORS_RALS_SUCCESS,

  GET_WILAYAS_FAILURE,
  GET_WILAYAS_REQUEST,
  GET_WILAYAS_SUCCESS,
} = otherTypes;

export const getColorsRals = () => async (dispatch) => {
  dispatch({ type: GET_COLORS_RALS_REQUEST });

  const config = {
    url: endpoint_get_rals_colors,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios(config);
    dispatch({ type: GET_COLORS_RALS_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_COLORS_RALS_FAILURE, payload: error });
  }
};
export const getWilayas = () => async (dispatch) => {
  dispatch({ type: GET_WILAYAS_REQUEST });

  const config = {
    url: enpoint_get_wilayas,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios(config);
    dispatch({ type: GET_WILAYAS_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_WILAYAS_FAILURE, payload: error });
  }
};
