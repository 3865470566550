import { snacksTypes } from "app/redux/types";

const initState = {
  snacks: [],
};

const snacksReducer = (state = initState, action) => {
  switch (action.type) {
    case snacksTypes.ENQUEUE_SNACK:
      return {
        ...state,
        snacks: [
          ...state.snacks,
          {
            key: action.key,
            ...action.snack,
          },
        ],
      };
    case snacksTypes.CLOSE_SNACK:
      return {
        ...state,
        snacks: state.snacks.map((snack) =>
          action.dimissAll || snack.key === action.key
            ? { ...snack, dismissed: true }
            : { ...snack }
        ),
      };
    case snacksTypes.REMOVE_SNACK:
      return {
        ...state,
        snacks: state.snacks.filter((snack) => snack.key !== action.key),
      };
    default:
      return state;
  }
};

export default snacksReducer;
