import { lazy } from "react";

export const lazyLoadAdminComponents = {
  Dash: lazy(() => import("pages/admin/Dash")),
  SignIn: lazy(() => import("pages/admin/SignIn")),
};

export const lazyLoadGuestComponents = {
  Landing: lazy(() => import("pages/Landing")),
  About: lazy(() => import("pages/About")),
  Contact: lazy(() => import("pages/Contact")),
  Products: lazy(() => import("pages/Products")),
  ProductDetails: lazy(() => import("pages/ProductDetails")),
  Quote: lazy(() => import("pages/Quote")),
  Team: lazy(() => import("pages/Team")),
  Blog: lazy(() => import("pages/Blog")),
  BlogDetails: lazy(() => import("pages/BlogDetails")),
};
