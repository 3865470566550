/* DROP ZONE STYLES */
export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  // padding: "30px",
  width: "100%",
  height: "100%",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export const focusedStyle = {
  borderColor: "#2196f3",
};

export const acceptStyle = {
  borderColor: "#00e676",
};

export const rejectStyle = {
  borderColor: "#ff1744",
};
