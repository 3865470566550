import {
  endpoint_delete_order,
  endpoint_get_orders,
  endpoint_get_orders_details,
} from "app/API/config";
import axiosInterceptor from "app/helpers/interceptor";
import { quotesTypes } from "app/redux/types";

const {
  GET_QUOTES_REQUEST,
  GET_QUOTES_SUCCESS,
  GET_QUOTES_FAILURE,

  DELETE_QUOTE_FAILURE,
  DELETE_QUOTE_REQUEST,
  DELETE_QUOTE_SUCCESS,

  GET_QUOTES_DETAILS_FAILURE,
  GET_QUOTES_DETAILS_REQUEST,
  GET_QUOTES_DETAILS_SUCCESS,
} = quotesTypes;

export const getQuotes = () => async (dispatch) => {
  dispatch({ type: GET_QUOTES_REQUEST });

  const config = {
    url: endpoint_get_orders,
    method: "GET",
  };

  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: GET_QUOTES_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_QUOTES_FAILURE, payload: error });
  }
};

export const getDetailsQuotes = (id) => async (dispatch) => {
  dispatch({ type: GET_QUOTES_DETAILS_REQUEST });
  const config = {
    url: endpoint_get_orders_details.replace(":id", id),
    method: "GET",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({ type: GET_QUOTES_DETAILS_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_QUOTES_DETAILS_FAILURE, payload: error });
  }
};

export const deleteQuotes = (id) => async (dispatch) => {
  dispatch({ type: DELETE_QUOTE_REQUEST });
  const config = {
    url: endpoint_delete_order.replace(":id", id),
    method: "DELETE",
  };
  try {
    const response = await axiosInterceptor(config);
    dispatch({
      type: DELETE_QUOTE_SUCCESS,
      payload: id,
      response: response,
    });
  } catch (error) {
    dispatch({ type: DELETE_QUOTE_FAILURE, payload: error });
  }
};
