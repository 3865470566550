import { Breadcrumbs, Link } from "@mui/material";
import { redirect } from "app/helpers/historyDom";
import React from "react";

const BreadCrumbsNav = ({ navigation }) => {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {navigation.map((item, index) => {
          return (
            <Link
              key={index}
              component="button"
              onClick={() => {
                redirect(item.path);
              }}
              underline={item.current ? "none" : "hover"}
              sx={{ display: "flex", alignItems: "center" }}
              color={item.current ? "text.primary" : "inherit"}
            >
              {item.icon}
              {item.label}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default BreadCrumbsNav;
