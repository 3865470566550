import { quotesTypes } from "app/redux/types";

const {
  GET_QUOTES_REQUEST,
  GET_QUOTES_SUCCESS,
  GET_QUOTES_FAILURE,

  DELETE_QUOTE_FAILURE,
  DELETE_QUOTE_REQUEST,
  DELETE_QUOTE_SUCCESS,

  GET_QUOTES_DETAILS_FAILURE,
  GET_QUOTES_DETAILS_REQUEST,
  GET_QUOTES_DETAILS_SUCCESS,
} = quotesTypes;

const initState = {
  quotes: [],
  quotes_details: {},
  loading_quotes: false,
  loading_quotes_details : false , 
  error_quotes: null,
  error_quotes_details: null,
};

const quotesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_QUOTES_REQUEST:
    case DELETE_QUOTE_REQUEST:
      return {
        ...state,
        loading_quotes: true,
      };
    case GET_QUOTES_FAILURE:
    case DELETE_QUOTE_FAILURE:
      return {
        ...state,
        loading_quotes: false,
        error_quotes: action.payload,
      };
    case GET_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.payload,
        loading_quotes: false,
        error_quotes: null,
      };
    case DELETE_QUOTE_SUCCESS:
      return {
        ...state,
        quotes: state.quotes.filter(
          (quote) => quote.id.toString() !== action.payload.toString()
        ),
        loading_quotes: false,
        error_quotes: null,
      };
    case GET_QUOTES_DETAILS_REQUEST:
      return {
        ...state,
        loading_quotes_details: true,
      };
    case GET_QUOTES_DETAILS_SUCCESS:
      return {
        ...state,
        quotes_details: action.payload,
        loading_quotes_details: false,
        error_quotes_details: null,
      };
    case GET_QUOTES_DETAILS_FAILURE:
      return {
        ...state,
        loading_quotes_details: false,
        error_quotes_details: action.payload,
      };
    default:
      return state;
  }
};

export default quotesReducer;
