import { Typography } from "@mui/material";
import React from "react";

const Title = ({ title, ...props }) => {
  return (
    <Typography variant="h4" align="left" gutterBottom {...props}>
      {title}
    </Typography>
  );
};

export default Title;
