import React from "react";
/* theme provider */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { frFR, arSD, enUS } from "@mui/material/locale";
import { themeLight } from "theme/muiTheme";
import { CssBaseline, Slide } from "@mui/material";
// import Landing from "pages/Landing";
import Routes from "app/routes/Routes";
import { SnackbarProvider } from "notistack";
import Notifier from "utils/Notifier";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { localeMap } from "app/helpers/localMarkup";
import { useSelector } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RefreshToken from "utils/RefreshToken";

/**
 * Create a theme from a base theme and a locale, and apply it to the document
 * @returns The `<ThemeProvider>` component is returning the `<CssBaseline />` component.
 */
function App() {
  const contentState = useSelector((state) => state.contentState);
  const { preferd_language } = contentState;

  const appliedTheme = createTheme(
    themeLight,
    preferd_language === "ar"
      ? arSD
      : preferd_language === "en"
      ? enUS
      : preferd_language === "fr"
      ? frFR
      : frFR
  );

  const authState = useSelector((state) => state.authState);
  const { user } = authState;

  /* For RTL support. */
  // // Create rtl cache
  // const cacheRtl = createCache({
  //   key: "muirtl",
  //   stylisPlugins: [prefixer, rtlPlugin],
  // });

  return (
    <ThemeProvider theme={appliedTheme}>
      <div dir={preferd_language === "ar" ? "rtl" : "ltr"}>
        <CssBaseline />
        <SnackbarProvider
          dense
          preventDuplicate
          maxSnack={1}
          // persist
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
        >
          <Notifier />
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={localeMap[preferd_language]}
          >
            <Routes />

            {user !== null && <RefreshToken />}
          </LocalizationProvider>
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
