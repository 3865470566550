export const fileTypesImages = {
  "image/*": [".jpeg", ".png", ".jpg"],
};

export const fileTypesFiles = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc", ".docx"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.ms-excel": [".xls", ".xlsx"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  // autodesk files
  "application/vnd.autodesk.3ds": [".3ds"],
  "application/vnd.autodesk.3mf": [".3mf"],
  "application/vnd.autodesk.fbx": [".fbx"],
  "application/vnd.autodesk.maya": [".ma", ".mb"],
  "application/vnd.autodesk.maya.binary": [".ma", ".mb"],
  "application/vnd.autodesk.maya.bundle": [".ma", ".mb"],
  "application/vnd.autodesk.maya.iges": [".ma", ".mb"],
  "application/vnd.autodesk.maya.ma": [".ma", ".mb"],
  "application/vnd.autodesk.maya.mb": [".ma", ".mb"],
  "application/vnd.autodesk.maya.mdl": [".ma", ".mb"],
  "application/vnd.autodesk.maya.mesh": [".ma", ".mb"],
  "application/vnd.autodesk.maya.obj": [".ma", ".mb"],
  "application/vnd.autodesk.maya.plugin": [".ma", ".mb"],
  "application/vnd.autodesk.maya.usd": [".ma", ".mb"],
  "application/vnd.autodesk.maya.vdb": [".ma", ".mb"],
  "application/vnd.autodesk.maya.xsi": [".ma", ".mb"],
  //3D files
  "application/vnd.ms-3mf": [".3mf"],
  "application/vnd.oasis.opendocument.graphics": [".odg"],
  "application/vnd.oasis.opendocument.graphics-template": [".otg"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "application/vnd.oasis.opendocument.presentation-template": [".otp"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.oasis.opendocument.spreadsheet-template": [".ots"],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "application/vnd.oasis.opendocument.text-master": [".odm"],
  "application/vnd.oasis.opendocument.text-template": [".ott"],
  "application/vnd.oasis.opendocument.text-web": [".oth"],
};
