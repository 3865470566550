import { CloseRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { snacksTypes } from "app/redux/types";

/* NOTIFICATION HANDLER */

/**
 *
 * @param {Object} snack
 * @param {String} snack.message
 * @param {Object} snack.options
 * @param {String} snack.options.variant
 * @param {JSX.Element} snack.options.action // optional return a component to close the snack
 * @returns {Object}
 */
export const enqueueSnackbar = (snack) => {
  const key = snack.options && snack.options.key;
  return {
    type: snacksTypes.ENQUEUE_SNACK,
    snack: {
      ...snack,
      key: key || new Date().getTime() + Math.random(), //create new key if key has not been defined
    },
  };
};

/**
 *
 * @param {String} key
 * @returns {Object}
 */
export const closeSnackbar = (key) => ({
  type: snacksTypes.CLOSE_SNACK,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});
export const removeSnackbar = (key) => ({
  type: snacksTypes.REMOVE_SNACK,
  key,
});

export const useSnack = (msg, variant) => (dispatch) => {
  dispatch({
    type: snacksTypes.ENQUEUE_SNACK,
    //generate new unique key for each snack
    key: new Date().getTime().toString(),
    snack: {
      message: msg,
      options: {
        // persist: false,
        preventDuplicate: true,
        variant: variant, //info , warrning , error , success
        action: (key) => (
          <IconButton
            onClick={() =>
              dispatch({
                type: snacksTypes.CLOSE_SNACK,
                key: key,
              })
            }
            color="inherit"
            size="small"
          >
            <CloseRounded />
          </IconButton>
        ),
      },
    },
  });
};
