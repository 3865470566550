import { Box, Paper } from "@mui/material";
import React from "react";

const CardDashPaper = ({ children }) => {
  return (
    <Box
      component={Paper}
      sx={{
        px: 2,
        py: 2,
        borderRadius: 4,
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export default CardDashPaper;
