import { ArrowBack } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { redirect } from "app/helpers/historyDom";
import { scopes } from "app/routes/routeList";
import { storyset } from "assets/assetsManager";
import React from "react";
import { useSelector } from "react-redux";

const NotFound = () => {
  // const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const contentState = useSelector((state) => state.contentState);
  const { content, preferd_language } = contentState;
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 1,
      }}
    >
      <img
        src={storyset.notfound}
        alt="svg not found"
        style={{ width: "100%", height: 450 }}
      />

      <Button
        variant="contained"
        startIcon={<ArrowBack />}
        color="primary"
        onClick={() => redirect(scopes.guest.home)}
      >
        {content.not_found[preferd_language]}
      </Button>
    </Box>
  );
};

export default NotFound;
